import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import useStyles from './Select.style';

function Select(props) {
  const classes = useStyles();

  const { children, className, ...textFieldProps } = props;

  return (
    <TextField
      {...textFieldProps}
      select
      hiddenLabel
      size="small"
      autoFocus
      className={[className, classes.select].join(' ')}
      InputLabelProps={{ shrink: true }}
      sx={{
        margin: { xs: '1em 0', md: '2em 0' },
      }}
    >
      {children}
    </TextField>
  );
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
};

Select.defaultProps = {
  className: '',
  required: false,
  label: '',
};

export default Select;
