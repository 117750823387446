import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Backdrop,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import { LogoSNCF, Loading } from 'assets';

export default function LoadingScreen() {
  const { t } = useTranslation();

  return (
    <Backdrop
      open
      sx={{
        backgroundColor: '#0000',
        backdropFilter: 'blur(1px)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Card
        sx={{
          width: 600,
          boxShadow: 5,
          p: 4,
        }}
      >
        <Grid container direction="column" alignItems="center">
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                fontWeight: 700,
                marginBottom: 0,
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: {
                  xs: 16,
                  sm: 18,
                  md: 21,
                  lg: 25,
                },
              }}
            >
              {t('loading.description')}
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            image={Loading}
            alt={t('loading.img_loading_alt')}
            sx={{ width: '158px' }}
          />
        </Grid>
        <Grid container direction="row" alignItems="center" wrap="nowrap">
          <CardMedia
            component="img"
            image={LogoSNCF}
            alt={t('header.logo_alt')}
            sx={{ width: '89px' }}
          />

          <LinearProgress
            color="secondary"
            sx={{
              width: { xs: 200, md: 350 },
              height: 10,
              borderRadius: 5,
              marginLeft: '3em',
            }}
          />
        </Grid>
      </Card>
    </Backdrop>
  );
}
