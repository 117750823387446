import React, { useEffect } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { TRAINEE } from 'config/constants/Roles';
import { APP_LOADING, HOME } from 'navigation/RoutesConstants';

import { useDispatch } from 'react-redux';
import { saveUserState } from 'redux/reducers/userReducer';

function Loading() {
  const navigate = useNavigate();

  // TODO CHANGE ME
  const USER_STUB = {
    firstname: 'DS',
    lastname: 'ITN',
    role: TRAINEE,
    email: 'dsitn@sncf.fr',
  };

  const dispatch = useDispatch();
  const isUserAuthentificated = useIsAuthenticated();
  useEffect(() => {
    if (isUserAuthentificated) {
      // Appelle le endpoint graphql de campus formation
      // Sauvegarde les informations de l'utilisateur après vérification de leurs validités
      dispatch(saveUserState(USER_STUB));
      if (
        window.location.pathname === `/${HOME}` ||
        window.location.pathname === `/${APP_LOADING}`
      ) {
        navigate(`/${HOME}`);
      } else {
        navigate(window.location.pathname);
      }
    }
  }, [isUserAuthentificated]);

  return (
    <>
      <LoadingScreen />
    </>
  );
}

Loading.propTypes = {};

export default Loading;
