import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  center: {
    flexGrow: 1,
    marginTop: '0.5em',
    padding: '1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: 1,
  },
  description: {
    letterSpacing: '0px',
    color: '#000000',
    textAlign: 'left',
    paddingRight: '0%',
    [theme.breakpoints.up('md')]: {
      paddingRight: '5%',
    },
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2em',
      marginBottom: '1em',
    },
  },
  img: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '7px',
  },
  leftImg: {
    display: 'block',
    width: '90%',
    height: '100vh',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '7px',
  },
  rightImg: {
    display: 'block',
    width: '90%',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '7px',
    maxHeight: '100vh',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.up('md')]: {
      height: '80%',
    },
  },
  modalImg: {
    width: '80%',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: 'auto',
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
