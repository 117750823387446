import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'pages/Loading/Loading';
import ResidentsList from 'pages/ResidentsList/ResidentsList';
import ArrivalAnnouncement from 'pages/ArrivalAnnouncement/ArrivalAnnouncement';
import InterventionRequest from 'pages/InterventionRequest/InterventionRequest';
import moment from 'moment';
import MyProfile from 'pages/MyProfile/MyProfile';
import Catering from 'pages/Catering/Catering';
import Agenda from '../pages/Agenda/Agenda';
import NotFound from './NotFound/NotFound';
import Home from '../pages/Home/Home';
import Accomodation from '../pages/Accomodation/Accomodation';
import Contact from '../pages/Contact/Contact';
import PublicHome from '../pages/PublicHome/PublicHome';
import CampusInformation from '../pages/CampusInformation/CampusInformation';
import GlobalFormation from '../pages/GlobalFormation/GlobalFormation';
import Hebergement from '../pages/Hebergement/Hebergement';
import News from '../pages/News/News';
import CampusNews from '../pages/CampusNews/CampusNews';
import {
  APP_LOADING,
  AUTH_CALLBACK,
  ROOT,
  NOTFOUND,
  CAMPUS_INFORMATION,
  WELCOME_BOOKLET,
  GLOBAL_FORMATION,
  AGENDA,
  HOME,
  APP_TO_CONSTRUCT,
  RESIDENTS_LIST,
  HEBERGEMENT,
  COMMUNICATE_ITS_ARRIVAL,
  INTERVENTION_REQUEST,
  ADMIN,
  MY_PROFILE,
  NEWS,
  ACCOMMODATION,
  WEEK_MENU_UPLOAD,
  CATERING,
  WEEK_MENU,
  GESTION_REQUEST,
  CONTACT,
  CAMPUS_NEWS,
  PRACTICAL_INFORMATION,
  CAMPUS_MAP,
} from './RoutesConstants';
import LeftMenu from './Drawer/LeftMenu';
import HeaderBar from './HeaderBar/HeaderBar';
import WelcomeBooklet from '../pages/WelcomeBooklet/WelcomeBooklet';
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';
import FormationRoute from './auth/FormationRoute';
import InConstruction from '../pages/InConstruction/InConstruction';
import AdminSpace from '../pages/AdminSpace/AdminSpace';
import WeekMenuUpload from '../pages/WeekMenuUpload/WeekMenuUpload';
import WeekMenu from '../pages/WeekMenu/WeekMenu';
import PraticalInformation from '../pages/PraticalInformation/PraticalInformation';
import GestionRequest from '../pages/GestionRequest/GestionRequest';
import CampusMap from '../pages/CampusMap/CampusMap';
import PublicRoute from './auth/PublicRoute';

function RouterConfig() {
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const today = moment();

  if (
    user?.isAuthenticated() &&
    // ne pas enlever le === null, il faut que la valeur retournée soit `null` et pas `undefined`
    user?.currentParticipation?.departure === null &&
    user?.currentParticipation?.arrival === null &&
    today.isBefore(user?.currentParticipation?.session?.startDate) &&
    window.location.pathname !== `/${COMMUNICATE_ITS_ARRIVAL}`
  ) {
    navigate(`/${COMMUNICATE_ITS_ARRIVAL}`);
  }

  return (
    <>
      <HeaderBar open={openBurgerMenu} setOpen={setOpenBurgerMenu} />

      {user?.isAuthenticated() && (
        <LeftMenu open={openBurgerMenu} setOpen={setOpenBurgerMenu} />
      )}

      <Routes>
        {/* Routes publiques */}

        <Route
          exact
          path={ROOT}
          element={
            <PublicRoute>
              <PublicHome />
            </PublicRoute>
          }
        />
        <Route exact path={APP_TO_CONSTRUCT} element={<InConstruction />} />
        <Route exact path={AUTH_CALLBACK} element={<Loading />} />
        <Route exact path={APP_LOADING} element={<Loading />} />

        {/* Routes privées */}
        <Route
          exact
          path={HOME}
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={WELCOME_BOOKLET}
          element={
            <PrivateRoute>
              <WelcomeBooklet />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AGENDA}
          element={
            <PrivateRoute>
              <Agenda />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={CAMPUS_INFORMATION}
          element={
            <PrivateRoute>
              <CampusInformation />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={GLOBAL_FORMATION}
          element={
            <PrivateRoute>
              <GlobalFormation />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={ACCOMMODATION}
          element={
            <PrivateRoute>
              <Accomodation />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={CAMPUS_NEWS}
          element={
            <PrivateRoute>
              <CampusNews />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={PRACTICAL_INFORMATION}
          element={
            <PrivateRoute>
              <PraticalInformation />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={CONTACT}
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={CATERING}
          element={
            <PrivateRoute>
              <Catering />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={WEEK_MENU}
          element={
            <PrivateRoute>
              <WeekMenu />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={CAMPUS_MAP}
          element={
            <PrivateRoute>
              <CampusMap />
            </PrivateRoute>
          }
        />
        {/* Routes stagiaire/formateur */}
        <Route
          exact
          path={MY_PROFILE}
          element={
            <FormationRoute>
              <MyProfile />
            </FormationRoute>
          }
        />
        <Route
          exact
          path={INTERVENTION_REQUEST}
          element={
            <FormationRoute>
              <InterventionRequest />
            </FormationRoute>
          }
        />
        {/* Routes admins */}
        <Route
          exact
          path={ADMIN}
          element={
            <AdminRoute>
              <AdminSpace />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={RESIDENTS_LIST}
          element={
            <AdminRoute>
              <ResidentsList />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={HEBERGEMENT}
          element={
            <AdminRoute>
              <Hebergement />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={NEWS}
          element={
            <AdminRoute>
              <News />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={WEEK_MENU_UPLOAD}
          element={
            <AdminRoute>
              <WeekMenuUpload />
            </AdminRoute>
          }
        />
        <Route
          exact
          path={GESTION_REQUEST}
          element={
            <AdminRoute>
              <GestionRequest />
            </AdminRoute>
          }
        />
        {user?.isAuthenticated() &&
          user?.currentParticipation?.departure === null &&
          user?.currentParticipation?.arrival === null &&
          today.isBefore(user?.currentParticipation?.session?.startDate) && (
            <Route
              exact
              path={COMMUNICATE_ITS_ARRIVAL}
              element={
                <PrivateRoute>
                  <ArrivalAnnouncement />
                </PrivateRoute>
              }
            />
          )}
        {/* Route générique 404 Not-Found */}
        <Route path={NOTFOUND} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default RouterConfig;
