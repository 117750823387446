import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'center',
    margin: '1em auto',
    [theme.breakpoints.up('md')]: {
      aspectRatio: 3 / 2,
      width: '80%',
      margin: '1em 0',
    },
    [theme.breakpoints.up('xs')]: {
      height: '90%',
    },
    cursor: 'pointer',
  },
  cardContent: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
  },
  icon: {
    width: '100%',
    height: '75%',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.00rem !important',
    },
    textTransform: 'uppercase',
  },
}));

export default useStyles;
