import React, { useEffect } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { me, saveUserState } from 'redux/reducers/userReducer';
import RequestAccessToken from 'services/requestAccessToken.service';
import Ressource from 'services/ressource.service';
import RouterConfig from './navigation/RouterConfig';
import globalTheme from './styles/globalTheme';

const theme = createTheme(globalTheme);

function App() {
  const isUserAuthentificated = useIsAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserAuthentificated) {
      const endpoint =
        process.env
          .REACT_APP_SERVICE_CAMPUS_FORMATION_ASSETS_RESSOURCES_ENDPOINT;
      dispatch(me()).then((d) => {
        Ressource.searchSiteForOctopusCampusId(d.payload.campus.id).then(
          (data) => {
            const welcomeBooklet = data.assets.find(
              (asset) => asset.keyname === 'PCF_welcome_booklet'
            );

            const mainBuilding = data.buildings.find(
              (building) => building.mainBuilding === true
            );
            dispatch(
              saveUserState({
                welcomeBooklet: `${endpoint}${welcomeBooklet.path}`,
                mainBuilding,
              })
            );
          }
        );
      });

      let options;
      RequestAccessToken((response) => {
        const { accessToken } = response;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'image/jpeg');
        options = {
          method: 'GET',
          headers,
        };
      }).then(() => {
        fetch('https://graph.microsoft.com/v1.0/me/photo/$value', options)
          .then((response) => {
            if (response && response.ok) {
              response.blob().then((data) => {
                if (data) {
                  window.URL = window.URL || window.webkitURL;
                  dispatch(
                    saveUserState({
                      photoUrl: window.URL.createObjectURL(data),
                    })
                  );
                }
              });
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Profile photo not found', error);
          });
      });
    }
  }, [isUserAuthentificated]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
