import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  center: {
    marginTop: 0,
    padding: '0.5em 1.5em 1.5em 1.5em ',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  description: {
    color: '#000000',
    textAlign: 'start',
    paddingTop: '5%',
    margin: '1em',
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2em',
    },
  },
  iframe: {
    height: '80vh',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
      height: 0,
    },
  },
}));

export default useStyles;
