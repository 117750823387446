import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import useStyles from './UseLocationMenu.style';

export default function UseLocationMenu() {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const bookmark = searchParams.get('signet');

  const addressArray = location.pathname.split('/', -1);
  const name = addressArray[1];
  const baseTitle =
    t(`use_location_menu.${name}`).search(/>/) !== -1 ? (
      <>
        <Link to="/" className={classes.text}>
          {t(`use_location_menu.${name}`).split('>')[0]}
        </Link>
        {'>'}
        <Link to={`/${name}`} className={classes.text}>
          {t(`use_location_menu.${name}`).split('>')[1]}
        </Link>
      </>
    ) : (
      <Link to={`/${name}`} className={classes.text}>
        {t(`use_location_menu.${name}`)}
      </Link>
    );
  const linksSupPart = [];
  Object.values(addressArray.slice(2)).forEach((addressPart, i) => {
    const showAddress =
      addressPart !== '/' && addressPart !== ''
        ? t(`use_location_menu.${addressPart}`)
        : '';
    let adresseComplete = '';
    for (let j = 1; j <= i + 1; j++) {
      adresseComplete += addressArray[j];
    }
    linksSupPart.push(
      <Link to={`/${adresseComplete}/${addressPart}`} className={classes.text}>
        {showAddress.length > 0 ? ` > ${showAddress}` : showAddress}
      </Link>
    );
  });

  return (
    <Grid item xs={12} md={12} className={classes.container}>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.text}
        sx={{ fontWeight: '700', mt: '0.62em' }}
      >
        {baseTitle}
        {linksSupPart.map((linkSupPart) => linkSupPart)}
        {bookmark && ` > ${t(`bookmark.${bookmark}`, '')}`}
      </Typography>
    </Grid>
  );
}
