import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './ArrivalAnnouncement.style';
import ProfileInformation from '../../components/ProfileInformation/ProfileInformation';

export default function ArrivalAnnouncement() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <Grid container>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {t('arrival_announcement.title').toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container direction="row" className={classes.description}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('arrival_announcement.description')}
                </Typography>
              </Grid>
            </Grid>
            <ProfileInformation isFirstVisit />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
