/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { KeyboardReturnRounded, BookmarksOutlined } from '@mui/icons-material/';
import axios from 'axios';
import IconCard from '../../components/IconCard/IconCard';
import useStyles from './WelcomeBooklet.style';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import MobileNavBar from '../../components/MobileNavBar/MobileNavBar';

function WelcomeBooklet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const bookmark = searchParams.get('signet');

  const AccueilPDF =
    bookmark?.length > 0 && user?.welcomeBooklet
      ? `${user?.welcomeBooklet}#nameddest=${bookmark}&toolbar=0&navpanes=0`
      : user?.welcomeBooklet
      ? `${user?.welcomeBooklet}#toolbar=0&navpanes=0`
      : null;

  const onDownload = async () => {
    const { data } = await axios.get(user?.welcomeBooklet, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    });
    const blob = new Blob([data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.download = `livret_accueil_${user.campus.name ?? ''}.pdf`;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  return (
    <>
      <main>
        <Box className={classes.center}>
          <Grid container spacing={2} className={classes.background}>
            <UseLocationMenu />
            <Grid item xs={12} md={4}>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.leftContainer}
                  alignItems="baseline"
                  wrap="nowrap"
                  gap={2}
                >
                  <IconCard
                    Icon={BookmarksOutlined}
                    card="cardSmall"
                    sxCard={{ minWidth: 54 }}
                  />
                  <Typography
                    variant="h5"
                    className={classes.title}
                    sx={{
                      fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.5rem' },
                      fontWeight: 'bold',
                      margin: 'auto 0',
                    }}
                  >
                    {t('welcome_booklet.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.leftContainer}
                  gap={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    sx={{
                      textAlign: { xs: 'center', sm: 'initial', md: 'initial' },
                    }}
                  >
                    <Typography className={classes.description}>
                      {t('welcome_booklet.description', {
                        name: user?.campus?.name,
                      })}
                    </Typography>
                    {AccueilPDF ? (
                      <Button
                        sx={{
                          paddingX: { xs: '1em', sm: 0, md: '1em' },
                          backgroundColor: '#767676',
                          color: 'white',
                          marginTop: { xs: '50%', sm: 0, md: '1em' },
                          textTransform: 'uppercase',
                        }}
                        variant="contained"
                        onClick={onDownload}
                      >
                        {t('welcome_booklet.download')}
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                textAlign: { xs: 'center', sm: 'center', md: 'left' },
              }}
            >
              {AccueilPDF ? (
                <iframe
                  src={`${AccueilPDF}`}
                  title="Livret d'accueil campus"
                  height="500vh"
                  width="40%"
                  frameBorder="0"
                  className={classes.iframe}
                >
                  <p>Ce navigateur ne supporte pas les PDFs !</p>
                </iframe>
              ) : (
                <p>{t('welcome_booklet.no_file')}</p>
              )}
            </Grid>
          </Grid>
          <MobileNavBar
            onClickFirst={() => navigate(-1)}
            cardFirst="cardSmall"
            IconFirst={KeyboardReturnRounded}
          />
        </Box>
      </main>
    </>
  );
}

export default WelcomeBooklet;
