import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
    marginBottom: '1em',
  },
  center: {
    flexGrow: 1,
    marginTop: '0.5em',
    padding: '1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: 1,
  },
  description: {
    letterSpacing: '0px',
    color: '#000000',
    textAlign: 'justify',
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2em',
    },
  },
  svgIcon: {
    marginTop: '0.3em',
    [theme.breakpoints.up('md')]: {
      marginTop: '1.5em',
    },
  },
  ahref: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
}));

export default useStyles;
