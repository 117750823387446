import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MailIcon from '@mui/icons-material/Mail';

export default function Unauthorized() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: '3em 5em',
      }}
    >
      <p>{t('not_authorized_page_message')}</p>
      <Button
        variant="contained"
        color="primary"
        startIcon={<MailIcon />}
        href={`mailto:${t('not_authorized_page_mailto')}`}
        sx={{ textAlign: 'center' }}
      >
        &nbsp;{t('contact_support')}
      </Button>
    </Box>
  );
}
