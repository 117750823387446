import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import useStyles from './CardPicture.style';

export default function CardPicture({
  title,
  image,
  alt,
  action = () => null,
}) {
  const classes = useStyles();

  return (
    <Card sx={{ maxWidth: { xs: 400, lg: 500 } }}>
      <CardActionArea onClick={action}>
        <CardMedia
          component="img"
          className={classes.cardMedia}
          image={image}
          alt={alt}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom className={classes.title}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardPicture.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};
