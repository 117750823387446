import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import {
  Hotel,
  ExploreOutlined,
  RestaurantOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import {
  INTERVENTION_REQUEST,
  ACCOMMODATION,
  CATERING,
  CAMPUS_MAP,
} from 'navigation/RoutesConstants';
import AddressComposer from 'utils/AddressComposer/AddressComposer';
import useStyles from './Home.style';
import { MapMarkerArround, BegleHome } from '../../assets';
import MenuItemComponent from '../../components/MenuItemComponent/MenuItemComponent';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import FloatingActionButton from '../../components/FloatingActionButton/FloatingActionButton';
import { gmapsUrl } from '../../config/constants/Links';

function Home() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const title = t('title_campus', { name: user?.campus?.name });
  const description = t('description', { name: user?.campus?.name });
  const address = AddressComposer(user?.mainBuilding);

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={6}>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  sx={{ fontWeight: '700', letterSpacing: '0px' }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                >
                  {description}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <MenuItemComponent
                  Icon={Hotel}
                  label={t('common.accommodation')}
                  link={`/${ACCOMMODATION}`}
                  description={t('link.hosting.description')}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <MenuItemComponent
                  Icon={RestaurantOutlined}
                  label={t('common.catering')}
                  link={`/${CATERING}`}
                  description={t('link.Restoration.description')}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <a
                  href={`${gmapsUrl}${address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={classes.ahref}
                >
                  <MenuItemComponent
                    Icon={MapMarkerArround}
                    label={t('common.go_to_campus')}
                    description={t('link.go_to_campus.description')}
                  />
                </a>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <MenuItemComponent
                  Icon={ExploreOutlined}
                  label={t('common.circulate')}
                  link={`/${CAMPUS_MAP}`}
                  description={t('link.orientate_and_circulate.description')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <img
              src={BegleHome}
              alt={t('img.campus_home')}
              className={classes.img}
            />
          </Grid>
        </Grid>
      </Box>
      <div className={classes.floatingActionButton}>
        <FloatingActionButton>
          <Button onClick={() => navigate(`/${INTERVENTION_REQUEST}`)}>
            <FeedbackOutlinedIcon sx={{ fontSize: 30 }} />
          </Button>
        </FloatingActionButton>
      </div>
    </main>
  );
}

export default Home;
