import React from 'react';
import { Grid, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import useStyles from './InConstruction.style';
import { InBuild } from '../../assets';

function InConstruction() {
  const { t } = useTranslation();
  const classes = useStyles();
  const title = t('in_construction.title');
  return (
    <main>
      <Box className={classes.center}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.background}
        >
          <Grid
            item
            xs={4}
            md={4}
            style={{ textAlign: 'center', marginTop: '10%' }}
          >
            <img
              src={InBuild}
              alt={t('in_construction.image_work_alt')}
              style={{ width: '20%' }}
            />
            <br />
            {title}
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}

export default InConstruction;
