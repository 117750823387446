import { gql } from '@apollo/client';
import { GraphqlQuery } from '../config/apolloClient';

import RequestAccessToken from './requestAccessToken.service';

const FormData = require('form-data');
const axios = require('axios');

const SEARCH_SITE_FOR_OCTOPUS_CAMPUS_ID = gql`
  query searchSiteForOctopusCampusId($octopusCampusId: Float!) {
    searchSiteForOctopusCampusId(
      octopusCampusId: $octopusCampusId
      assets: true
    ) {
      id
      assets {
        id
        keyname
        originalname
        uri
        uniqueKeyname
        assetableSourceClass
        assetableSourceId
        mimetype
        path
      }
      buildings {
        id
        name
        address
        addressComplement
        postalCode
        city
        mainBuilding
        floors {
          id
          name
        }
      }
    }
  }
`;

const ASSETS_SITE = gql`
  query findAssetsBySite($siteId: Float!, $tableSourceClass: String!) {
    findAssetsBySite(siteId: $siteId, tableSourceClass: $tableSourceClass) {
      id
      assetableSourceClass
      assetableSourceId
      keyname
      uniqueKeyname
      originalname
      uri
      path
      mimetype
    }
  }
`;

const SEARCH_SITE_RECEPTION_EMAIL_FOR_OCTOPUS_CAMPUS_ID = gql`
  query searchSiteForOctopusCampusId($octopusCampusId: Float!) {
    searchSiteForOctopusCampusId(octopusCampusId: $octopusCampusId) {
      id
      receptionEmail
    }
  }
`;

export default class Ressource {
  static searchSiteForOctopusCampusId(octopusCampusId) {
    return GraphqlQuery(SEARCH_SITE_FOR_OCTOPUS_CAMPUS_ID, {
      octopusCampusId,
    })
      .then((result) => result?.searchSiteForOctopusCampusId)
      .catch((e) => e);
  }

  static findAssetsBySite(siteId, tableSourceClass) {
    return GraphqlQuery(ASSETS_SITE, {
      siteId,
      tableSourceClass,
    })
      .then((result) => result.findAssetsBySite)
      .catch((e) => e);
  }

  static searchSiteReceptionEmailForOctopusCampusId(octopusCampusId) {
    return GraphqlQuery(SEARCH_SITE_RECEPTION_EMAIL_FOR_OCTOPUS_CAMPUS_ID, {
      octopusCampusId,
    })
      .then((result) => result?.searchSiteForOctopusCampusId)
      .catch((e) => e);
  }

  static uploadWeekMenu(file) {
    const data = new FormData();
    let config;
    data.append(
      'operations',
      '{ "query":" mutation uploadWeekMenuFile($file: Upload!) {    uploadWeekMenuFile(file: $file)  }", "variables": { "file": null } }'
    );
    data.append('map', '{ "0": ["variables.file"] }');
    data.append('0', file);
    return RequestAccessToken((response) => {
      const { accessToken } = response;
      config = {
        method: 'post',
        url: process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_GRAPHQL_ENDPOINT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data,
      };
    }).then(() =>
      axios(config)
        .then((response) => response.data.data.uploadWeekMenuFile)
        .catch((error) => {
          throw error;
        })
    );
  }
}
