import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, MenuItem } from '@mui/material';
import { Select, InputText } from 'components/Inputs';
import { useTranslation } from 'react-i18next';
import { KeyboardReturnRounded, Close, Check } from '@mui/icons-material/';
import MobileNavBar from 'components/MobileNavBar/MobileNavBar';
import { useNavigate } from 'react-router-dom';
import {
  sortArrayByName,
  sortArrayByNameByLettersThenNumbers,
} from 'utils/array.util';
import useStyles from './CreateRequestMobile.style';

export default function CreateRequestMobile({
  categories,
  siteName,
  buildings,
  form,
  setFormValueOf,
  errors,
  helperTextError,
  messageSent,
  sendForm,
  setForm,
  setToggleAccordionCreate,
  setToggleAccordionMyRequest,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [types, setTypes] = useState([]);
  const [floors, setFloors] = useState([]);

  useEffect(() => {
    const [category] = categories.filter((cat) => cat.id === form.category);
    setTypes(category?.types.sort(sortArrayByName) || []);
  }, [form.category]);

  useEffect(() => {
    const [building] = buildings.filter((build) => build.id === form.building);
    setFloors(building?.floors.sort(sortArrayByNameByLettersThenNumbers) || []);
  }, [form.building]);

  return (
    <div>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Typography
            className={classes.titles}
            sx={{
              letterSpacing: '0px',
              marginBottom: '1em',
              fontSize: '14px',
              fontWeight: '700',
            }}
          >
            {t('intervention_request.create.request_subject')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Select
            required
            fullWidth
            label={t('intervention_request.create.category')}
            value={form.category}
            onChange={(e) => setFormValueOf('category', e.target.value)}
            error={errors && !form.category}
            helperText={helperTextError(form.category)}
            inputProps={{ 'data-testid': 'category' }}
          >
            {categories.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Select
            required
            fullWidth
            label={t('intervention_request.create.request_type')}
            value={form.type}
            onChange={(e) => setFormValueOf('type', e.target.value)}
            error={errors && !form.type}
            helperText={helperTextError(form.type)}
            inputProps={{ 'data-testid': 'requestType' }}
            disabled={!form.category}
          >
            {types.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Typography
            className={classes.titles}
            sx={{
              letterSpacing: '0px',
              marginBottom: '1em',
              fontSize: '14px',
              fontWeight: '700',
            }}
          >
            {t('intervention_request.create.request_location')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <InputText
            required
            label={t('intervention_request.create.site')}
            value={siteName}
            focused
            // Gestion d'erreur en attente retour métier
            // error={errors && !form.siteName}
            inputProps={{ 'data-testid': 'site' }}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Select
            required
            fullWidth
            label={t('intervention_request.create.building')}
            value={form.building}
            onChange={(e) => setFormValueOf('building', e.target.value)}
            error={errors && !form.building}
            helperText={helperTextError(form.building)}
            inputProps={{ 'data-testid': 'building' }}
          >
            {buildings.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Select
            required
            fullWidth
            label={t('intervention_request.create.floor')}
            value={form.floor}
            onChange={(e) => setFormValueOf('floor', e.target.value)}
            error={errors && !form.floor}
            helperText={helperTextError(form.floor)}
            inputProps={{ 'data-testid': 'floor' }}
            disabled={!form.building}
          >
            {floors.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <InputText
            label={t('intervention_request.create.comment')}
            multiline
            fullWidth
            rows={3}
            placeholder={t('intervention_request.create.comment_placeholder')}
            focused
            value={form.comment}
            onChange={(e) => setFormValueOf('comment', e.target.value)}
            inputProps={{ maxLength: 500, 'data-testid': 'comment' }}
          />
        </Grid>

        <Grid item xs={12} sx={{ width: '100%' }}>
          <Typography sx={{ mb: 1.5, mt: 1 }} color="#0088CE">
            {messageSent}
          </Typography>
        </Grid>
      </Grid>
      <MobileNavBar
        onClickFirst={() => navigate(-1)}
        cardFirst="cardSmall"
        IconFirst={KeyboardReturnRounded}
        onClickSecond={() => {
          setForm({
            category: '',
            type: '',
            building: '',
            floor: '',
            site: '',
            comment: '',
          });
          setToggleAccordionCreate(false);
          setToggleAccordionMyRequest(false);
        }}
        cardSecond="cardSmall"
        cardContentSecond="cardContentSmallRondedDark"
        IconSecond={() => <Close color="primary" />}
        sxCardSecond={{ borderRadius: '50px' }}
        onClickThird={sendForm}
        cardThird="cardSmall"
        cardContentThird="cardContentSmallButtonBlue"
        IconThird={() => <Check color="primary" />}
      />
    </div>
  );
}

CreateRequestMobile.propTypes = {
  siteName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf().isRequired,
  buildings: PropTypes.arrayOf().isRequired,
  form: PropTypes.shape({
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }).isRequired,
  setFormValueOf: PropTypes.func.isRequired,
  errors: PropTypes.bool.isRequired,
  helperTextError: PropTypes.func.isRequired,
  messageSent: PropTypes.node.isRequired,
  sendForm: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  setToggleAccordionCreate: PropTypes.func.isRequired,
  setToggleAccordionMyRequest: PropTypes.func.isRequired,
};
