import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { KeyboardReturnRounded, Restaurant } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  WEEK_MENU,
  WELCOME_BOOKLET_BOOKMARK,
} from 'navigation/RoutesConstants';
import CardPicture from 'components/CardPicture/CardPicture';
import MobileNavBar from 'components/MobileNavBar/MobileNavBar';
import IconCard from '../../components/IconCard/IconCard';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './Catering.style';

import weekMenuPicture from '../../assets/images/week_menu.jpg';
import whereToLunch from '../../assets/images/where_to_lunch.jpg';

export default function Catering() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [catering] = user.campus.siteAttributes.filter(
    (res) => res.name === 'cateringText'
  );

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={10}>
            <Grid
              container
              direction="row"
              className={classes.leftContainer}
              alignItems="baseline"
              wrap="nowrap"
              gap={2}
            >
              <IconCard
                Icon={Restaurant}
                card="cardSmall"
                sxCard={{ minWidth: 54 }}
              />
              <Typography
                variant="h5"
                className={classes.title}
                sx={{
                  fontSize: { xs: '1.1rem', md: '1.5rem' },
                  fontWeight: 'bold',
                  margin: 'auto 0',
                }}
              >
                {t('catering.title')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{ marginBottom: '1em' }}
                >
                  {catering.value}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              className={classes.leftContainer}
              alignItems="baseline"
              sx={{
                flexWrap: {
                  md: 'nowrap',
                },
              }}
              gap={2}
            >
              <Grid item xs={12} md={6}>
                <CardPicture
                  title={t('catering.week_menu')}
                  image={weekMenuPicture}
                  alt={t('catering.week_menu_alt')}
                  action={() => navigate(`/${WEEK_MENU}`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardPicture
                  title={t('catering.where_to_lunch')}
                  image={whereToLunch}
                  alt={t('catering.where_to_lunch_alt')}
                  action={() => navigate(`/${WELCOME_BOOKLET_BOOKMARK}lunch`)}
                />
              </Grid>
            </Grid>
          </Grid>
          {window.innerWidth < 900 && (
            <MobileNavBar
              onClickFirst={() => navigate(-1)}
              cardFirst="cardSmall"
              IconFirst={KeyboardReturnRounded}
            />
          )}
        </Grid>
      </Box>
    </main>
  );
}
