/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import RequestAccessToken from 'services/requestAccessToken.service';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_GRAPHQL_ENDPOINT,
});

const authMiddleware = new ApolloLink(async (operation, forward) => {
  return RequestAccessToken((response) => {
    const { accessToken } = response;

    operation.setContext({
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });
  }).then(() => forward(operation));
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: concat(authMiddleware, httpLink),
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const GraphqlQuery = (query, variables = {}) => {
  return client
    .query({
      query,
      variables,
    })
    .then((result) => result.data);
};

const GraphqlMutate = (mutation, variables = {}) => {
  return client
    .mutate({
      mutation,
      variables,
    })
    .then((result) => result.data);
};

const ApolloProviderEncapsulation = (props) => (
  <ApolloProvider client={client}>{props.children}</ApolloProvider>
);

export default client;
export {
  GraphqlQuery,
  GraphqlMutate,
  ApolloProviderEncapsulation as ApolloProvider,
};
