import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '60px',
    width: 'min-content',
    aspectRatio: 1, // === 1/1, mais génère un bug Sonar
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      height: '80px',
      padding: '0.75em',
      aspectRatio: 1, // === 1/1, mais génère un bug Sonar
    },
  },
  cardContent: {
    textAlign: 'center',
    padding: '0.75rem',
    verticalAlign: 'middle',
    width: '100%',
  },
  img: {
    fontSize: 'xx-large',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: '0.1em',
    },
  },
  cardSmall: {
    [theme.breakpoints.down('md')]: {
      aspectRatio: 1, // === 1/1, mais génère un bug Sonar
      textAlign: 'center',
      height: '54px',
      padding: 0,
    },
  },
  cardSmallRondedBlue: {
    [theme.breakpoints.down('md')]: {
      aspectRatio: 1, // === 1/1, mais génère un bug Sonar
      textAlign: 'center',
      height: '54px',
      padding: 0,
    },
  },
  cardContentSmallRondedBlue: {
    [theme.breakpoints.down('md')]: {
      height: '54px',
      borderRadius: '50%',
      textAlign: 'center',
      padding: '0.75rem',
      verticalAlign: 'middle',
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  cardContentSmallRondedDark: {
    [theme.breakpoints.down('md')]: {
      height: '54px',
      borderRadius: '50%',
      textAlign: 'center',
      padding: '0.75rem',
      verticalAlign: 'middle',
      width: '100%',
      backgroundColor: theme.palette.download.main,
    },
  },
  cardContentSmallButton: {
    [theme.breakpoints.down('md')]: {
      height: '54px',
      textAlign: 'center',
      padding: '0.75rem',
      verticalAlign: 'middle',
      width: '100%',
      backgroundColor: theme.palette.download.main,
    },
  },
  cardContentSmallButtonBlue: {
    [theme.breakpoints.down('md')]: {
      height: '54px',
      textAlign: 'center',
      padding: '0.75rem',
      verticalAlign: 'middle',
      width: '100%',
      backgroundColor: '#3288CE',
    },
  },
}));

export default useStyles;
