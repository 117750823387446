import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {
  ArrowCircleRight,
  ArrowCircleLeft,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { Typography, TextField, MenuItem, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './CustomToolbar.style';

export default function CustomToolbar({
  date,
  view,
  views,
  onView,
  onNavigate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [viewName, setViewName] = useState('');

  const dayName = moment(date).startOf('day').format('dddd');
  const dayNumber = moment(date).startOf('day').format('DD');
  const monthOfDay = moment(date).startOf('day').format('MMMM');
  const yearOfDay = moment(date).startOf('day').format('YYYY');

  const weeknumber = moment(date).week();
  const dayOfStartWeek = moment(date).startOf('week').format('DD');
  const monthOfStartWeek = moment(date).startOf('week').format('MMMM');
  const dayOfEndWeek = moment(date).endOf('week').format('DD');
  const monthOfEndWeek = moment(date).endOf('week').format('MMMM');
  const yearOfEndWeek = moment(date).endOf('year').format('YYYY');

  const typeOfItem = (item) => {
    if (item === 'day') {
      return t('agenda.calendar_messages.day');
    }
    return t('agenda.calendar_messages.week');
  };

  useEffect(() => {
    setViewName(typeOfItem(view));
  }, [view]);

  const handleChange = (event) => {
    setViewName(event.target.value);
  };

  const goToView = (vue) => {
    onView(vue);
  };

  const goToBack = () => {
    if (view === 'day' && dayName === 'lundi') {
      onNavigate('DATE', new Date(moment(date).add(-3, 'days')));
    } else {
      onNavigate('PREV');
    }
  };

  const goToNext = () => {
    if (view === 'day' && dayName === 'vendredi') {
      onNavigate('DATE', new Date(moment(date).add(3, 'days')));
    } else {
      onNavigate('NEXT');
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      wrap="nowrap"
      className={classes.main}
    >
      <Grid item container alignItems="center">
        <ArrowCircleLeft
          onClick={goToBack}
          color="secondary"
          sx={{ fontSize: 27 }}
          className={classes.icon}
        />

        <Typography variant="p" className={classes.week}>
          {view === 'work_week'
            ? t('agenda.calendar_messages.week_number', {
                weeknumber,
                dayOfStartWeek,
                monthOfStartWeek,
                dayOfEndWeek,
                monthOfEndWeek,
                yearOfEndWeek,
              })
            : t('agenda.calendar_messages.day_number', {
                dayName,
                dayNumber,
                monthOfDay,
                yearOfDay,
              })}
        </Typography>

        <ArrowCircleRight
          onClick={goToNext}
          color="secondary"
          sx={{ fontSize: 27 }}
          className={classes.icon}
        />
      </Grid>

      <Grid item container alignItems="center" justifyContent="flex-end">
        <Typography variant="p" className={classes.mode}>
          {t('agenda.calendar_messages.display_mode_selector')}
        </Typography>

        <TextField
          select
          variant="outlined"
          value={viewName}
          onChange={handleChange}
          size="small"
          SelectProps={{
            IconComponent: () => <KeyboardArrowDown />,
          }}
          InputProps={{
            className: classes.input,
          }}
        >
          {views?.map((item) => {
            return (
              <MenuItem
                onClick={() => goToView(item)}
                key={item}
                value={typeOfItem(item)}
                className={classes.menuItem}
              >
                {typeOfItem(item)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    </Grid>
  );
}

CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  onView: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};
