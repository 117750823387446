import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { Select, InputText } from 'components/Inputs';
import OutlinedDiv from 'components/OutlinedDiv/OutlinedDiv';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HOME } from 'navigation/RoutesConstants';
import {
  sortArrayByName,
  sortArrayByNameByLettersThenNumbers,
} from 'utils/array.util';
import useStyles from './CreateRequestDesktop.style';

function CreateRequestDesktop({
  categories,
  siteName,
  buildings,
  form,
  setFormValueOf,
  sendForm,
  errors,
  helperTextError,
  messageSent,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [types, setTypes] = useState([]);
  const [floors, setFloors] = useState([]);

  useEffect(() => {
    const [category] = categories.filter((cat) => cat.id === form.category);
    setTypes(category?.types.sort(sortArrayByName) || []);
  }, [form.category]);

  useEffect(() => {
    const [building] = buildings.filter((build) => build.id === form.building);
    setFloors(building?.floors.sort(sortArrayByNameByLettersThenNumbers) || []);
  }, [form.building]);

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <OutlinedDiv label={t('intervention_request.create.request_subject')}>
          <Grid container spacing={2} direction="column" md={10}>
            <Grid item sx={{ width: '100%' }}>
              <Select
                label={t('intervention_request.create.category')}
                required
                inputProps={{ 'data-testid': 'createRequestDesktopCategory' }}
                value={form.category}
                error={errors && !form.category}
                helperText={helperTextError(form.category)}
                onChange={(e) => setFormValueOf('category', e.target.value)}
              >
                {categories.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item sx={{ width: '100%' }}>
              <Select
                label={t('intervention_request.create.request_type')}
                required
                inputProps={{ 'data-testid': 'createRequestDesktopType' }}
                value={form.type}
                error={errors && !form.type}
                helperText={helperTextError(form.type)}
                onChange={(e) => setFormValueOf('type', e.target.value)}
                disabled={!form.category}
              >
                {types.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid />
          </Grid>
        </OutlinedDiv>
      </Grid>

      <Grid item md={6} xs={12}>
        <OutlinedDiv label={t('intervention_request.create.request_location')}>
          <Grid container spacing={2} direction="column" md={10} xs={10}>
            <Grid item md={12}>
              <InputText
                inputProps={{ 'data-testid': 'createRequestDesktopSite' }}
                label={t('intervention_request.create.site')}
                value={siteName}
                required
                focused
                // Gestion d'erreur en attente retour métier
                // error={errors && !siteName}
              />
            </Grid>

            <Grid item container spacing={2}>
              <Grid item md={6} sx={{ width: '100%' }}>
                <Select
                  label={t('intervention_request.create.building')}
                  required
                  inputProps={{ 'data-testid': 'createRequestDesktopBuilding' }}
                  value={form.building}
                  error={errors && !form.building}
                  helperText={helperTextError(form.building)}
                  onChange={(e) => setFormValueOf('building', e.target.value)}
                >
                  {buildings.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={6} sx={{ width: '100%' }}>
                <Select
                  label={t('intervention_request.create.floor')}
                  required
                  inputProps={{ 'data-testid': 'createRequestDesktopFloor' }}
                  value={form.floor}
                  error={errors && !form.floor}
                  helperText={helperTextError(form.floor)}
                  onChange={(e) => setFormValueOf('floor', e.target.value)}
                  disabled={!form.building}
                >
                  {floors.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>

      <Grid item md={6} xs={12} sx={{ width: '100%' }}>
        <InputText
          multiline
          focused
          rows={4}
          label={t('intervention_request.create.comment')}
          inputProps={{
            maxLength: 500,
            'data-testid': 'createRequestDesktopComment',
          }}
          value={form.comment}
          onChange={(e) => setFormValueOf('comment', e.target.value)}
          placeholder={t('intervention_request.create.comment_placeholder')}
        />
      </Grid>

      <Grid
        item
        md={6}
        xs={12}
        container
        alignSelf="center"
        justifyContent="center"
      >
        <Grid item md={4} xs={4}>
          <Button
            size="small"
            data-testid="createRequestDesktopReturnBtn"
            classes={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/${HOME}`)}
          >
            {t('buttons.cancel')}
          </Button>
        </Grid>

        <Grid item md={7} xs={7}>
          <Button
            size="small"
            data-testid="createRequestDesktopSendAnomalyBtn"
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={sendForm}
          >
            {t('buttons.send_my_request')}
          </Button>
        </Grid>
      </Grid>
      <Typography sx={{ mb: 1.5, mt: 1 }} color="#0088CE">
        {messageSent}
      </Typography>
    </Grid>
  );
}

CreateRequestDesktop.propTypes = {
  siteName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf().isRequired,
  buildings: PropTypes.arrayOf().isRequired,
  form: PropTypes.shape({
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }).isRequired,
  setFormValueOf: PropTypes.func.isRequired,
  sendForm: PropTypes.func.isRequired,
  errors: PropTypes.bool.isRequired,
  helperTextError: PropTypes.func.isRequired,
  messageSent: PropTypes.node.isRequired,
};

export default CreateRequestDesktop;
