import RequestAccessToken from './requestAccessToken.service';

const FormData = require('form-data');
const axios = require('axios');

export default class Participations {
  static uploadAccommodationFile(file) {
    const data = new FormData();
    let config;
    data.append(
      'operations',
      '{ "query":" mutation uploadAccommodationFile($file: Upload!) {    uploadAccommodationFile(file: $file)  }", "variables": { "file": null } }'
    );
    data.append('map', '{ "0": ["variables.file"] }');
    data.append('0', file);
    return RequestAccessToken((response) => {
      const { accessToken } = response;
      config = {
        method: 'post',
        url: process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_GRAPHQL_ENDPOINT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data,
      };
    }).then(() =>
      axios(config)
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        })
    );
  }
}
