import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    height: '150px',
    [theme.breakpoints.up('md')]: {
      height: '250px',
    },
  },
  cardContent: {
    textAlign: 'center',
  },
  title: {
    textTransform: 'uppercase',
  },
}));

export default useStyles;
