import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ContactImg } from 'assets/index';
import Ressource from 'services/ressource.service';
import useStyles from './Contact.style';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';

function Contact() {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [receptionEmail, setReceptionEmail] = useState('');
  const [contacterDisabled, setContacterDisabled] = useState(true);

  useEffect(async () => {
    if (user?.campus) {
      try {
        const site = await Ressource.searchSiteReceptionEmailForOctopusCampusId(
          user.campus.id
        );
        setReceptionEmail(site.receptionEmail);
        setContacterDisabled(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [user]);

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          {window.innerWidth < 900 && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                letterSpacing: '0px',
                marginBottom: '0',
                marginLeft: '0.6em',
                textTransform: 'uppercase',
              }}
            >
              {t('use_location_menu.forum')}
            </Typography>
          )}
          <Grid
            item
            xs={11}
            md={11}
            sx={{
              marginLeft: { md: '1em', xs: '0' },
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                background: '#FFFFFF',
                borderRadius: '7px',
              }}
            >
              {/* row 1 */}
              <Grid container direction="row" className={classes.leftContainer}>
                <Grid item xs />
                <Grid item xs={10} md={4}>
                  <Typography
                    variant="h6"
                    className={classes.description}
                    gutterBottom
                    sx={{
                      marginBottom: '3em',
                      marginTop: '3em',
                      fontWeight: 'bold',
                      fontSize: { md: '1.25rem', xs: '0.8rem' },
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    }}
                  >
                    {t('contact.description')}
                  </Typography>
                </Grid>
                <Grid item xs />
              </Grid>
              {/* row 2 */}
              <Grid container direction="row" className={classes.leftContainer}>
                <Grid item xs />
                <Grid item xs={10} md={4}>
                  <Grid container direction="row">
                    <Grid item sx={{ margin: 'auto' }}>
                      <Button
                        sx={{
                          backgroundColor: '#0188ce',
                          color: 'white',
                          textTransform: 'uppercase',
                          marginBottom: '3em',
                          fontSize: '0.7rem',
                          paddingX: { xs: '3em', md: '5em' },
                        }}
                        disabled={contacterDisabled}
                        variant="contained"
                        onClick={() => {
                          window
                            .open(`mailto:${receptionEmail}`, '_blank')
                            .focus();
                        }}
                      >
                        {t('contact.button_contact')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs />
              </Grid>
            </Grid>
            <Grid container className={classes.imgContainer}>
              <img
                sx={{
                  width: '100%',
                  height: '170%',
                  objectFit: 'cover',
                }}
                src={ContactImg}
                alt={t('img.formation')}
                className={classes.img}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}

export default Contact;
