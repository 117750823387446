import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from 'pages/Loading/Loading';

function PublicRoute({ children }) {
  const user = useSelector((state) => state.user);

  const isLoading = () => {
    if (user?.loading) {
      return <Loading />;
    }
    if (!user?.loading) {
      return children;
    }
    return false;
  };

  return isLoading();
}

PublicRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PublicRoute;
