export default function ValidationForm(setErrors, sendNext = () => null) {
  // Récupère tous les inputs du DOM
  const inputTextfields = document.querySelectorAll('input');
  // Si un type d'input est problématique, modifier en : querySelector: 'input:not([type="checkbox"])'

  // S'il n'y pas d'input on peut passer à la page suivante
  const arrayValidation = [true];
  inputTextfields.forEach((input) => {
    if (input.required && input.value) {
      arrayValidation.push(true);
    }
    if (input.required && !input.value) {
      arrayValidation.push(false);
    }
    if (input.pattern === '[0-9]*' && input.value.length !== 10) {
      arrayValidation.push(false);
    }
  });

  const areAllElementTrue = (currentValue) => currentValue === true;

  // Vérifie que tous les inputs soient valides, et passe à la page suivante si c'est le cas, sinon affiche les erreurs
  if (arrayValidation.every(areAllElementTrue)) {
    return sendNext();
  }

  setErrors(true);
}
