/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  Skeleton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Event from 'services/event.service';
import Ressource from 'services/ressource.service';
import Popup from 'components/Popup/Popup';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import UploadCard from '../../components/UploadCard/UploadCard';
import useStyles from './News.style';

export default function News() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [listNews, setListNews] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [siteId, setSiteId] = useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    Ressource.searchSiteForOctopusCampusId(user.campus.id)
      .then((response) => {
        if (response) {
          Event.allBySite(response.id)
            .then((results) => {
              setAllSelected(false);
              setChecked(
                results.map(() => {
                  return false;
                })
              );
              setListNews(results);
              setIsLoaded(true);
            })
            .catch((e) => console.error(e));
          setSiteId(response.id);
        }
      })
      .catch(() => console.error('Une erreur est survenue\n'));
  }, []);

  const handleChange = (index) => {
    const checkedCopy = [...checked];
    checkedCopy[index] = !checkedCopy[index];
    setChecked(checkedCopy);
  };

  const selectAll = () => {
    if (allSelected) {
      setChecked(
        checked.map(() => {
          return false;
        })
      );
    } else {
      setChecked(
        checked.map((oneNews, index) => {
          if (index < 5) {
            return true;
          }
          return false;
        })
      );
    }
    setAllSelected(!allSelected);
  };

  const children = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: 3,
      }}
    >
      {listNews
        ?.sort((a, b) => b.id - a.id)
        .slice(0, 5)
        .map((oneNews, index) => {
          return (
            <>
              <FormControlLabel
                label={oneNews.name}
                key={oneNews.name}
                control={
                  <Checkbox
                    checked={checked[index] || false}
                    onClick={() => handleChange(index)}
                    color="secondary"
                    sx={{
                      marginLeft: '0.2em',
                    }}
                  />
                }
              />
              <Divider />
            </>
          );
        })}
    </Box>
  );

  const uploadFile = (file) =>
    Event.createEvent(file, siteId)
      .then((result) => {
        const newList = listNews;
        newList.push(result.createEvent);
        setListNews(newList);
        setChecked(
          newList.map(() => {
            return false;
          })
        );
        return result;
      })
      .catch((e) => console.error(e));

  const deleteNews = async () => {
    const newList = listNews?.filter((elem, index) => {
      return checked[index] === false;
    });

    const removedNews = listNews?.filter((elem, index) => {
      return checked[index] === true;
    });

    removedNews?.map((oneNews) => {
      Event.delete(oneNews.id).catch((e) => console.error(e));
      return true;
    });
    setChecked(
      newList.map(() => {
        return false;
      })
    );

    setListNews(newList);
    setPopupOpen(false);
  };

  function loadingRowSkeleton() {
    const skeleton = [];
    for (let i = 0; i < 5; i += 1) {
      skeleton.push(
        <Skeleton
          variant="text"
          animation="wave"
          height="2.8em"
          sx={{ margin: '0 0.5em 0 2em' }}
        />
      );
    }
    return (
      <>
        <Skeleton
          variant="text"
          animation="wave"
          height="2em"
          sx={{ margin: '0 0.5em' }}
        />
        {skeleton}
      </>
    );
  }

  return (
    <main>
      {popupOpen && (
        <Popup
          description={t('news.delete_message')}
          buttonText={t('buttons.validate')}
          buttonTextClose={t('buttons.cancel')}
          isClosable
          handleAction={deleteNews}
          handleClose={() => setPopupOpen(false)}
        />
      )}
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={6}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700, marginBottom: '0.3em' }}
                >
                  {t('news.add_news')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{
                    height: {
                      xs: '7em',
                      sm: '4.5em',
                      md: '3.5em',
                      lg: '2.5em',
                    },
                  }}
                >
                  {t('news.add_news_description')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} sx={{ minHeight: '300px' }}>
                <UploadCard
                  importLabel={t('news.import_label')}
                  fileSendLabel={t('news.file_send')}
                  typeFile={['jpeg', 'jpg', 'png']}
                  sizeMaxFile="1 Mo"
                  uploadFile={uploadFile}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700, marginBottom: '0.3em' }}
                >
                  {t('news.history_news')}
                </Typography>
              </Grid>
              <Grid item xs={11} md={11}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{
                    height: {
                      xs: '7em',
                      sm: '4.5em',
                      md: '3.5em',
                      lg: '2.5em',
                    },
                  }}
                >
                  {t('news.history_news_description')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.rightContainer}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="column"
                  sx={{
                    width: '92%',
                  }}
                >
                  <Grid item xs={12}>
                    <Card>
                      <CardContent sx={{ minHeight: '300px' }}>
                        <Grid container direction="column">
                          <Grid item xs={12} md={12}>
                            {!isLoaded ? (
                              loadingRowSkeleton()
                            ) : listNews.length !== 0 ? (
                              <div>
                                <FormControlLabel
                                  label={t('news.select_all')}
                                  control={
                                    <Checkbox
                                      checked={allSelected || false}
                                      onChange={selectAll}
                                      color="secondary"
                                      sx={{ display: 'none' }}
                                    />
                                  }
                                  sx={{
                                    marginLeft: '0.5em',
                                    color: '#000000',
                                    opacity: '50%',
                                  }}
                                />
                                {children}
                              </div>
                            ) : (
                              <Typography
                                align="center"
                                sx={{ marginTop: '3.4%' }}
                              >
                                {t('news.empty_history')}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        marginTop: {
                          xs: '0.5em',
                          sm: '1.5em',
                          md: '2.5em',
                          lg: '4em',
                        },
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        sx={{ textTransform: 'uppercase' }}
                        onClick={() => setPopupOpen(true)}
                        disabled={checked.every((check) => check === false)}
                      >
                        {t('buttons.delete')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
