import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TrashCanOutline } from '../../assets';

export default function CardAnomalyMobile({
  id,
  type,
  category,
  place,
  createdAt,
  updatedAt,
  status,
  commentary = '',
  setSelectDelete,
}) {
  const { t } = useTranslation();
  const labelStyle = { color: '#0188CE' };
  const dataStyle = { textAlign: 'right' };
  const renderStatus = (params) => {
    let bgColor;
    let fColor;
    switch (params.value) {
      case `${t('my_request.array.create')}`:
        bgColor = '#0088CE';
        fColor = '#FFF';
        break;
      case `${t('my_request.array.in_progress')}`:
        bgColor = '#4D4F53';
        fColor = '#FFF';
        break;
      case `${t('my_request.array.waiting')}`:
        bgColor = '#F2F2F2';
        fColor = '#3C3C3B';
        break;
      default:
        bgColor = '#F3F3F3';
        fColor = '#3C3C3B';
        break;
    }
    return (
      <Typography
        sx={{
          backgroundColor: bgColor,
          color: fColor,
          fontSize: '13px',
          padding: '0.3em 1em',
          borderRadius: '50px',
          textAlign: 'center',
        }}
      >
        {params.value}
      </Typography>
    );
  };

  return (
    <Card
      sx={{
        backgroundColor: '#FAFAFA',
        marginBottom: '2em',
        paddingTop: '2em',
      }}
      key={`anomaly_${id}`}
    >
      <CardContent>
        <Grid container direction="row" rowSpacing={2}>
          <Grid item xs={12}>
            {category} - {type}
          </Grid>
          <Grid item xs={7} style={labelStyle}>
            {t('my_request.array.status')}
          </Grid>
          <Grid item xs={5}>
            {renderStatus({ value: status })}
          </Grid>
          <Grid item xs={7} style={labelStyle}>
            {t('my_request.array.place')}
          </Grid>
          <Grid item xs={5} style={dataStyle}>
            {place}
          </Grid>
          <Grid item xs={7} style={labelStyle}>
            {t('my_request.array.create_date')}
          </Grid>
          <Grid item xs={5} style={dataStyle}>
            {createdAt}
          </Grid>
          <Grid item xs={7} style={labelStyle}>
            {t('my_request.array.update_date')}
          </Grid>
          <Grid item xs={5} style={dataStyle}>
            {updatedAt}
          </Grid>
          <Grid item xs={12} style={labelStyle}>
            {t('my_request.array.commentary')}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'justify' }}>
            {commentary}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <TrashCanOutline
              data-testid="deleteIcon"
              cursor="pointer"
              fill={status === t('my_request.array.create') ? 'black' : 'grey'}
              onClick={() => setSelectDelete(id)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CardAnomalyMobile.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  commentary: PropTypes.string,
  setSelectDelete: PropTypes.func,
};
CardAnomalyMobile.defaultProps = {
  commentary: '',
  setSelectDelete: () => null,
};
