import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
    marginBottom: '1em',
    marginLeft: '1em',
  },
  img: {
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '7px',
    width: '100%',
    height: '170%',
    'object-fit': 'cover',
  },
  imgContainer: {
    height: '180px',
    margin: '2em 0 10em 0',
    [theme.breakpoints.down('md')]: {
      height: '120px',
      marginTop: '1em',
    },
  },
  center: {
    flexGrow: 1,
    marginTop: '0.5em',
    padding: '1.5em 0em 1.5em 1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  description: {
    letterSpacing: '0px',
    color: '#000000',
    fontWeight: 'bold',
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2em',
    },
    paddingRight: '0',
  },
}));

export default useStyles;
