import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import {
  Grid,
  Typography,
  Box,
  Skeleton,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { ReactSvgPanZoomLoader } from 'react-svg-pan-zoom-loader';
import {
  KeyboardReturnRounded,
  OpenInNewOffRounded,
  OpenInNewRounded,
  ExploreOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Ressource from 'services/ressource.service';
import MobileNavBar from 'components/MobileNavBar/MobileNavBar';
import IconCard from '../../components/IconCard/IconCard';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './CampusMap.style';

export default function CampusMap() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [openImage, setOpenImage] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listBuildings, setListBuildings] = useState([]);
  const [building, setBuilding] = useState('');
  const [listFloors, setListFloor] = useState([]);
  const [floor, setFloor] = useState();
  const [map, setMap] = useState(null);
  const user = useSelector((state) => state.user);
  const [width, height] = useWindowSize({
    initialWidth: 400,
    initialHeight: 400,
  });
  const getOrientation = () => window?.screen?.orientation?.type;
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = () => {
    setOrientation(getOrientation());
  };

  const endpoint =
    process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_ASSETS_RESSOURCES_ENDPOINT;

  useEffect(() => {
    Ressource.searchSiteForOctopusCampusId(user.campus.id).then((res) => {
      setListBuildings(res.buildings);
      setBuilding(t('campus_map.select_building'));
      setFloor(t('campus_map.select_floor'));

      const tempListFloor = [];
      res.buildings.map((item) =>
        item.floors.map((element) =>
          Ressource.findAssetsBySite(element.id, 'floor').then((response) => {
            const url = `${endpoint}${response[0].path}`;
            const plan = {
              id: element.id,
              url,
            };
            tempListFloor.push(plan);
          })
        )
      );

      setListFloor(tempListFloor);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, [window?.screen?.orientation?.type]);

  const handleChangeBuilding = (event) => {
    setBuilding(event.target.value);
    setFloor(t('campus_map.select_floor'));
    setMap();
  };
  const handleChangeFloor = (event) => {
    setFloor(event.target.value);
    setMap(
      event.target.value === t('campus_map.select_floor')
        ? null
        : listFloors.find((item) => item.id === event.target.value).url
    );
  };

  function loadingRowSkeleton() {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="80%"
        height={350}
        sx={{ margin: '2em 1em', borderRadius: '10px' }}
      />
    );
  }

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={10}>
            <Grid
              container
              direction="row"
              className={!openImage ? classes.openImage : classes.leftContainer}
              alignItems="baseline"
              wrap="nowrap"
              gap={2}
            >
              <IconCard
                Icon={ExploreOutlined}
                card="cardSmall"
                sxCard={{ minWidth: 54 }}
              />
              <Typography
                variant="h5"
                className={classes.title}
                sx={{
                  fontSize: { xs: '1.1rem', md: '1.5rem' },
                  fontWeight: 'bold',
                  margin: 'auto 0',
                }}
              >
                {t('campus_map.title')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              className={!openImage ? classes.openImage : classes.leftContainer}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{ marginBottom: '1em' }}
                >
                  {t('campus_map.description')}
                </Typography>
              </Grid>
            </Grid>
            {!isLoaded ? (
              loadingRowSkeleton()
            ) : (
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={building}
                      onChange={handleChangeBuilding}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={t('campus_map.select_building')}>
                        <em>{t('campus_map.select_building')}</em>
                      </MenuItem>
                      {listBuildings.map((item) => {
                        return (
                          <MenuItem value={item.name} key={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    {listBuildings.map((item) => {
                      const buildingName = item.name;
                      if (building === buildingName) {
                        return (
                          <Select
                            value={floor}
                            onChange={handleChangeFloor}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value={t('campus_map.select_floor')}>
                              <em>{t('campus_map.select_floor')}</em>
                            </MenuItem>
                            {item.floors.map((element) => {
                              return (
                                <MenuItem value={element.id} key={element.id}>
                                  {element.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        );
                      }
                      return null;
                    })}
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: ' 0px 5%',
                  }}
                >
                  {orientation !== 'landscape-primary'
                    ? map && (
                        <ReactSvgPanZoomLoader
                          src={map}
                          render={(content) => (
                            <UncontrolledReactSVGPanZoom
                              width={width}
                              height={height - 300}
                              defaultTool="pan"
                            >
                              <svg width={width} height={height - 300}>
                                {content}
                              </svg>
                            </UncontrolledReactSVGPanZoom>
                          )}
                        />
                      )
                    : map && (
                        <ReactSvgPanZoomLoader
                          src={map}
                          render={(content) => (
                            <UncontrolledReactSVGPanZoom
                              width={
                                window.innerWidth < 900 ? width : width - 200
                              }
                              height={height - 100}
                              defaultTool="pan"
                            >
                              <svg width={width} height={height - 100}>
                                {content}
                              </svg>
                            </UncontrolledReactSVGPanZoom>
                          )}
                        />
                      )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {window.innerWidth < 900 && (
          <MobileNavBar
            onClickFirst={() => navigate(-1)}
            cardFirst="cardSmall"
            IconFirst={KeyboardReturnRounded}
            onClickSecond={() => setOpenImage(!openImage)}
            cardSecond="cardSmallRondedBlue"
            cardContentSecond="cardContentSmallRondedBlue"
            sxCardSecond={{ borderRadius: '50px' }}
            IconSecond={() =>
              !openImage ? (
                <OpenInNewOffRounded color="primary" />
              ) : (
                <OpenInNewRounded color="primary" />
              )
            }
          />
        )}
      </Box>
    </main>
  );
}
