import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Badge, Clock } from 'assets';

import { WELCOME_BOOKLET_BOOKMARK } from 'navigation/RoutesConstants';
import { InfoOutlined, KeyboardReturnRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MobileNavBar from '../../components/MobileNavBar/MobileNavBar';
import IconCard from '../../components/IconCard/IconCard';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import ImgLabelCard from '../../components/ImgLabelCard/ImgLabelCard';
import useStyles from './PraticalInformation.style';

export default function PraticalInformation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={12}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.leftContainer}
                  alignItems="baseline"
                  wrap="nowrap"
                  gap={2}
                  sx={{ paddingLeft: 0 }}
                >
                  <IconCard
                    Icon={InfoOutlined}
                    card="cardSmall"
                    sxCard={{ minWidth: 54 }}
                  />
                  <Typography
                    variant="h5"
                    className={classes.title}
                    sx={{ fontWeight: 700, margin: 'auto 0' }}
                  >
                    {t('practical_information.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                >
                  {t('practical_information.description')}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.leftContainer}
              sx={{ marginBottom: '5em' }}
            >
              <Grid item xs={12} md={6} className={classes.img}>
                <ImgLabelCard
                  label={t('practical_information.badge_information')}
                  link={`/${WELCOME_BOOKLET_BOOKMARK}badge`} // TODO l'ancre sera à changer à l'avenir
                  img={Badge}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.img}>
                <ImgLabelCard
                  label={t('practical_information.opening_hours')}
                  link={`/${WELCOME_BOOKLET_BOOKMARK}schedule`} // TODO l'ancre sera à changer à l'avenir
                  img={Clock}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MobileNavBar
          onClickFirst={() => navigate(-1)}
          cardFirst="cardSmall"
          IconFirst={KeyboardReturnRounded}
        />
      </Box>
    </main>
  );
}
