import { PublicClientApplication } from '@azure/msal-browser';
import { ROOT_URL } from 'navigation/RoutesConstants';

export const MicrosoftAuthConfig = {
  auth: {
    clientId: process.env.REACT_APP_OMNIAUTH_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OMNIAUTH_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/common',
    postLogoutRedirectUri: ROOT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configure où le cache sera stocké
    storeAuthStateInCookie: false, // Mettre à true si il y a des problèmes sur IE11 ou Edge
  },
};

export const MicrosoftClient = new PublicClientApplication(MicrosoftAuthConfig);

// Ajouter un scope ici pour que le token ID soit utilisé par les endpoints de la plateforme d'identités Microsoft
export const loginRequest = {
  scopes: ['User.Read'],
};
