import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FileDownloadOutlined,
  KeyboardReturnRounded,
  OpenInNewOffRounded,
  OpenInNewRounded,
  Restaurant,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Ressource from 'services/ressource.service';
import MobileNavBar from 'components/MobileNavBar/MobileNavBar';
import Popup from 'components/Popup/Popup';
import IconCard from '../../components/IconCard/IconCard';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './WeekMenu.style';

export default function WeekMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const validMimeType = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

  const [popupOpen, setPopupOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const [menuPicture, setMenuPicture] = useState([]);
  const [openImage, setOpenImage] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [catering] = user.campus.siteAttributes.filter(
    (res) => res.name === 'weekMenuText'
  );

  useEffect(() => {
    Ressource.findAssetsBySite(user.campus.id, 'site').then((res) => {
      const ids = res.map((object) => {
        return object.id;
      });
      const max = Math.max(...ids);

      const lastAsset = res.filter((asset) => asset.id === max);
      const { mimetype } = lastAsset[0];
      setMenuPicture(lastAsset[0]);
      setIsLoaded(true);
      if (!validMimeType.includes(mimetype)) {
        setPopupOpen(true);
      }
    });
  }, []);

  const endpoint =
    process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_ASSETS_RESSOURCES_ENDPOINT;

  const url = `${endpoint}${menuPicture.path}`;

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.click();
  };

  function loadingRowSkeleton() {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="80%"
        height={250}
        sx={{ margin: '2em 1em', borderRadius: '10px' }}
      />
    );
  }

  return (
    <main>
      <Box className={classes.center}>
        {popupOpen && (
          <Popup
            title={t('catering.week_menu_popup.week_menu_title')}
            description={t('catering.week_menu_popup.week_menu_description')}
            buttonText={t('catering.week_menu_popup.week_menu_btn')}
            isClosable={false}
            handleAction={() => navigate(-1)}
          />
        )}
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={10}>
            <Grid
              container
              direction="row"
              className={!openImage ? classes.openImage : classes.leftContainer}
              alignItems="baseline"
              wrap="nowrap"
              gap={2}
            >
              <IconCard
                Icon={Restaurant}
                card="cardSmall"
                sxCard={{ minWidth: 54 }}
              />
              <Typography
                variant="h5"
                className={classes.title}
                sx={{
                  fontSize: { xs: '1.1rem', md: '1.5rem' },
                  fontWeight: 'bold',
                  margin: 'auto 0',
                }}
              >
                {t('week_menu.title')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              className={!openImage ? classes.openImage : classes.leftContainer}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{ marginBottom: '1em' }}
                >
                  {catering.value}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {!isLoaded ? (
                loadingRowSkeleton()
              ) : (
                <img
                  src={url}
                  alt={t('week_menu.week_menu_alt')}
                  className={!openImage ? classes.iframeOn : classes.iframe}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {window.innerWidth < 900 && (
          <MobileNavBar
            onClickFirst={() => navigate(-1)}
            cardFirst="cardSmall"
            IconFirst={KeyboardReturnRounded}
            onClickSecond={() => setOpenImage(!openImage)}
            cardSecond="cardSmallRondedBlue"
            cardContentSecond="cardContentSmallRondedBlue"
            sxCardSecond={{ borderRadius: '50px' }}
            IconSecond={() =>
              !openImage ? (
                <OpenInNewOffRounded color="primary" />
              ) : (
                <OpenInNewRounded color="primary" />
              )
            }
            onClickThird={onDownload}
            cardThird="cardSmall"
            cardContentThird="cardContentSmallButton"
            IconThird={() => <FileDownloadOutlined color="primary" />}
          />
        )}
      </Box>
    </main>
  );
}
