import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Ressource from 'services/ressource.service';
import Anomaly from 'services/anomaly.service';
import User from 'services/user.service';
import { Box } from '@mui/material';

import UseLocationMenu from 'components/UseLocationMenu/UseLocationMenu';
import MyAdministratorVision from './MyAdministratorVision/MyAdministratorVision';
import useStyles from './GestionRequest.style';
import Unauthorized from '../../navigation/Unauthorized/Unauthorized';

function GestionRequest() {
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const [anomalies, setAnomalies] = useState([]);
  const [loadingAnomalies, SetLoadingAnomalies] = useState(true);
  const [buildings, setBuildings] = useState([]);
  const [status, setStatus] = useState([]);

  useEffect(async () => {
    if (user?.campus) {
      Anomaly.status().then((StatusList) => setStatus(StatusList));
      try {
        const site = await Ressource.searchSiteForOctopusCampusId(
          user.campus.id
        );
        Anomaly.bySite({ id: site.id })
          .then((Anomalies) => {
            setAnomalies(Anomalies);
            SetLoadingAnomalies(false);
          })
          .catch(() =>
            console.error(
              "Une erreur est survenue pendant le chargement de la liste d'anomalies"
            )
          );
        if (site) {
          setBuildings(site?.buildings || []);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [user]);

  const deleteAnomaly = (id) => {
    if (Array.isArray(id)) {
      Anomaly.manyDelete(id)
        .then(() => {
          const anomaliesCopy = anomalies.filter(
            (anomaly) => !id.includes(anomaly.id)
          );
          setAnomalies(anomaliesCopy);
        })
        .catch(() =>
          console.error(
            "Une erreur est survenue pendant la suppression de l'anomalie"
          )
        );
    } else {
      Anomaly.delete(id)
        .then(() => {
          const anomaliesCopy = anomalies.filter(
            (anomaly) => anomaly.id !== id
          );
          setAnomalies(anomaliesCopy);
        })
        .catch(() =>
          console.error(
            "Une erreur est survenue pendant la suppression de l'anomalie"
          )
        );
    }
  };

  const updateAnomaly = (id, dataUpdateAnomaly) => {
    Anomaly.update(id, dataUpdateAnomaly)
      .then((anomaly) => {
        const anomaliesCopy = anomalies.filter((ano) => ano.id !== id);
        setAnomalies([...anomaliesCopy, anomaly]);
      })
      .catch(() =>
        console.error(
          "Une erreur est survenue pendant la mise à jour de l'anomalie"
        )
      );
  };
  const loadUser = (ids) => User.findByIds(ids);

  return user?.campus?.name ? (
    <Box className={classes.center}>
      <UseLocationMenu />
      <MyAdministratorVision
        siteName={user?.campus?.name}
        buildings={buildings}
        anomalies={anomalies}
        loadingAnomalies={loadingAnomalies}
        deleteAnomaly={deleteAnomaly}
        statusList={status}
        updateAnomaly={updateAnomaly}
        loadUser={loadUser}
      />
    </Box>
  ) : (
    <Unauthorized />
  );
}

export default GestionRequest;
