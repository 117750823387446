import { gql } from '@apollo/client';
import { GraphqlMutate, GraphqlQuery } from '../config/apolloClient';

const ANOMALIES = gql`
  fragment CoreAnomalies on Anomaly {
    id
    status {
      id
      name
    }
    createdAt
    updatedAt
    type {
      id
      name
      category {
        id
        name
      }
    }
    creatorUserId
    floorRessourceId
    comment
  }
`;
const ANOMALIES_BY_SITE = gql`
  ${ANOMALIES}
  query anomaliesBySite($id: Int!) {
    anomaliesBySite(id: $id) {
      ...CoreAnomalies
    }
  }
`;
const ANOMALIES_BY_SITE_AND_USER = gql`
  ${ANOMALIES}
  query anomaliesBySiteAndUser($id: Int!, $userId: Int!) {
    anomaliesBySiteAndUser(id: $id, userId: $userId) {
      ...CoreAnomalies
    }
  }
`;

const CREATE_ANOMALY = gql`
  ${ANOMALIES}
  mutation createAnomaly($input: CreateAnomalyInput!) {
    createAnomaly(createAnomalyInput: $input) {
      ...CoreAnomalies
    }
  }
`;

const GET_CATEGORIES_FOR_SITE_RESSOURCE_ID = gql`
  query getCategoriesForSiteRessourceId($siteRessourceId: Float!) {
    getCategoriesForSiteRessourceId(siteRessourceId: $siteRessourceId) {
      id
      name
      types {
        id
        name
        criticality {
          id
          name
        }
      }
    }
  }
`;

const DELETE_ANOMALY = gql`
  mutation deleteAnomaly($id: Int!) {
    deleteAnomaly(id: $id) {
      id
    }
  }
`;

const DELETE_MANY_ANOMALY = gql`
  mutation deleteManyAnomaly($ids: [Int!]!) {
    deleteManyAnomaly(ids: $ids) {
      id
    }
  }
`;

const STATUS_ANOMALY = gql`
  query status {
    anomalyStatus {
      id
      name
    }
  }
`;

const UPDATE_ANOMALY = gql`
  ${ANOMALIES}
  mutation updateAnomalies($id: Int!, $updateAnomaly: UpdateAnomalyInput!) {
    updateAnomaly(id: $id, updateAnomaly: $updateAnomaly) {
      ...CoreAnomalies
    }
  }
`;

export default class Anomaly {
  static create(input) {
    return GraphqlMutate(CREATE_ANOMALY, {
      input,
    })
      .then((result) => result.createAnomaly)
      .catch((e) => e);
  }

  static getCategoriesForSiteRessourceId(siteRessourceId) {
    return GraphqlQuery(GET_CATEGORIES_FOR_SITE_RESSOURCE_ID, {
      siteRessourceId,
    })
      .then((result) => result.getCategoriesForSiteRessourceId)
      .catch((e) => e);
  }

  static bySiteAndUser(input) {
    return GraphqlQuery(ANOMALIES_BY_SITE_AND_USER, input)
      .then((result) => result.anomaliesBySiteAndUser)
      .catch((e) => e);
  }

  static bySite(input) {
    return GraphqlQuery(ANOMALIES_BY_SITE, input)
      .then((result) => result.anomaliesBySite)
      .catch((e) => e);
  }

  static delete(id) {
    return GraphqlMutate(DELETE_ANOMALY, {
      id,
    })
      .then((result) => result.deleteAnomaly)
      .catch((e) => e);
  }

  static status() {
    return GraphqlQuery(STATUS_ANOMALY)
      .then((result) => result.anomalyStatus)
      .catch((e) => e);
  }

  static update(id, updateAnomaly) {
    return GraphqlMutate(UPDATE_ANOMALY, {
      id,
      updateAnomaly,
    }).then((result) => result.updateAnomaly);
  }

  static manyDelete(ids) {
    return GraphqlMutate(DELETE_MANY_ANOMALY, { ids })
      .then((result) => result.deleteManyAnomaly)
      .catch((e) => e);
  }
}
