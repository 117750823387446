import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from 'pages/Loading/Loading';
import Unauthorized from '../Unauthorized/Unauthorized';

function PrivateRoute({ children }) {
  const user = useSelector((state) => state.user);

  const isLoading = () => {
    if (user?.isAuthenticated() && !user?.loading) {
      return children;
    }
    if (user?.loading) {
      return <Loading />;
    }

    return <Unauthorized />;
  };

  return isLoading();
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PrivateRoute;
