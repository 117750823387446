import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Hotel,
  PinDropOutlined,
  KeyboardReturnRounded,
  FeedbackOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OfficeBuildingMarker, BegleHebergement, StairsUp } from 'assets/index';
import Popup from 'components/Popup/Popup';
import MobileNavBar from 'components/MobileNavBar/MobileNavBar';
import {
  INTERVENTION_REQUEST,
  APP_TO_CONSTRUCT,
} from 'navigation/RoutesConstants';
import IconCard from '../../components/IconCard/IconCard';
import useStyles from './Accomodation.style';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import IconLabelCard from '../../components/IconLabelCard/IconLabelCard';
import { gmapsUrl } from '../../config/constants/Links';

function Accomodation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const buildingName = t('accomodation.building_name');
  const [popupOpen, setPopupOpen] = useState(false);
  const [iconList, setIconList] = useState([]);
  const isHalfBoard = user?.currentParticipation?.isHalfBoard;
  const cardStyle = {
    aspectRatio: 'auto',
    cursor: 'default',
    margin: { xs: '1em 0', md: '0 1em 0 0' },
    maxWidth: { xs: '100%', md: 'fill-available' },
    height: { xs: '7em', md: 'auto' },
  };

  const svgOfficeIconFormat = () => {
    return (
      <OfficeBuildingMarker
        data-testid="buildingIcon"
        className={classes.svgIcon}
      />
    );
  };

  const svgStairsIconFormat = () => {
    return <StairsUp data-testid="stairsIcon" className={classes.svgIcon} />;
  };

  useEffect(() => {
    const hotelName = user?.currentParticipation?.hotelName;
    const hotelRoomNumber = user?.currentParticipation?.hotelRoomNumber;
    if (isHalfBoard && hotelName) {
      setIconList([
        {
          icon: svgOfficeIconFormat,
          label: hotelName.split(',')[0] ?? '',
          url: '',
        },
        {
          icon: PinDropOutlined,
          label: hotelName.split(',')[1] ?? t('accomodation.unknown_address'),
          url: '',
        },
      ]);
    } else if (!isHalfBoard && hotelRoomNumber) {
      const floor = hotelRoomNumber.charAt(0);
      setIconList([
        {
          icon: svgOfficeIconFormat,
          label: buildingName ? `Bâtiment ${buildingName}` : '',
          url: '',
        },
        {
          icon: svgStairsIconFormat,
          label: floor
            ? t('accomodation.floors', { count: parseInt(floor, 10) })
            : '',
          url: '',
        },
        {
          icon: Hotel,
          label: hotelRoomNumber
            ? t('accomodation.room', {
                hotelRoomNumber,
              })
            : '',
          url: '',
        },
      ]);
    } else {
      setPopupOpen(true);
    }
  }, [user]);

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={10}>
            <Grid
              container
              direction="row"
              className={classes.leftContainer}
              alignItems="baseline"
              wrap="nowrap"
              gap={2}
            >
              <IconCard Icon={Hotel} card="cardSmall" />
              <Typography
                variant="h5"
                className={classes.title}
                sx={{
                  fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.5rem' },
                  fontWeight: 'bold',
                }}
              >
                {t('accomodation.title')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                  sx={{ marginBottom: '1em' }}
                >
                  {t('accomodation.description')}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  maxWidth: { xs: '100%', md: isHalfBoard ? '100%' : '95%' },
                }}
              >
                {isHalfBoard
                  ? iconList.map((item) => {
                      return (
                        <Grid key={item.label} item xs={12} md={4}>
                          <IconLabelCard
                            label={item.label}
                            link={item.url}
                            Icon={item.icon}
                            sxCard={{
                              ...cardStyle,
                              width: { md: '40em' },
                            }}
                          />
                        </Grid>
                      );
                    })
                  : iconList.map((item) => {
                      return (
                        <Grid key={item.label} item xs={12} md={3}>
                          <IconLabelCard
                            label={item.label}
                            link={item.url}
                            Icon={item.icon}
                            sxCard={{
                              ...cardStyle,
                            }}
                          />
                        </Grid>
                      );
                    })}
                <Grid item xs={12} md={isHalfBoard ? 3.4 : 3}>
                  {((isHalfBoard && user?.currentParticipation?.hotelName) ||
                    (!isHalfBoard &&
                      user?.currentParticipation?.hotelRoomNumber)) && (
                    <Button
                      sx={{
                        paddingX: { xs: 0, sm: 0, md: '1.5em' },
                        backgroundColor: '#0188ce',
                        color: 'white',
                        margin: {
                          xs: 0,
                          sm: 0,
                          md: '7.85em auto auto 0',
                        },
                        textTransform: 'uppercase',
                        width: '100%',
                      }}
                      variant="contained"
                      onClick={() =>
                        isHalfBoard
                          ? window
                              .open(
                                `${gmapsUrl}${user?.currentParticipation?.hotelName}`,
                                '_blank'
                              )
                              .focus()
                          : navigate(`/${APP_TO_CONSTRUCT}`)
                      }
                    >
                      {isHalfBoard
                        ? t('accomodation.button_to_accomodation')
                        : t('accomodation.button_navigate_campus')}
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid container className={classes.imgContainer}>
                <img
                  src={BegleHebergement}
                  alt={t('img.formation')}
                  className={classes.img}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MobileNavBar
          onClickFirst={() => navigate(-1)}
          cardFirst="cardSmall"
          IconFirst={KeyboardReturnRounded}
          onClickSecond={() => navigate(`/${INTERVENTION_REQUEST}`)}
          cardSecond="cardSmallRondedBlue"
          cardContentSecond="cardContentSmallRondedBlue"
          sxCardSecond={{ borderRadius: '50px', color: 'white' }}
          IconSecond={FeedbackOutlined}
        />
        {popupOpen && (
          <Popup
            title={t('accomodation.popup_title')}
            description={t('accomodation.popup_desc')}
            buttonText={t('accomodation.popup_button')}
            isClosable={false}
            handleAction={() => navigate(-1)}
          />
        )}
      </Box>
    </main>
  );
}

export default Accomodation;
