/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  ImageList,
  ImageListItem,
  Skeleton,
  Modal,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Event from 'services/event.service';
import Ressource from 'services/ressource.service';
import { NewspaperOutlined } from '@mui/icons-material';

import IconCard from '../../components/IconCard/IconCard';
import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './CampusNews.style';

export default function CampusNews() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [listNews, setListNews] = useState([]);
  const [numColums, setNumColums] = useState(4);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState();
  const [modalImageAlt, setModalImageAlt] = useState();

  const user = useSelector((state) => state.user);
  const endpoint =
    process.env.REACT_APP_SERVICE_CAMPUS_FORMATION_ASSETS_EVENEMENTS_ENDPOINT;

  useEffect(() => {
    Ressource.searchSiteForOctopusCampusId(user.campus.id)
      .then((site) => {
        if (site) {
          Event.allBySite(site.id)
            .then((results) => {
              setListNews(results);
              setNumColums(
                window.innerWidth < 700
                  ? 2
                  : results.length <= 4
                  ? results.length
                  : 4
              );
              setIsLoaded(true);
            })
            .catch((e) => console.error(e));
        }
      })
      .catch(() => console.error('Une erreur est survenue\n'));
  }, []);

  const srcSet = (urlImage, size, rows, cols) => {
    return {
      src: `${urlImage}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${urlImage}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  };

  function loadingRowSkeleton() {
    return (
      <>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={800}
          sx={{ margin: '2em 1em', width: '90%' }}
        />
      </>
    );
  }

  const defineModal = (image, alt) => {
    setModalImage(image);
    setModalImageAlt(alt);
    setModalOpen(true);
  };

  return (
    <main>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <img
            className={classes.modalImg}
            src={modalImage}
            alt={modalImageAlt}
          />
        </Box>
      </Modal>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12} md={12}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.leftContainer}
                  alignItems="baseline"
                  wrap="nowrap"
                  gap={2}
                  sx={{ paddingLeft: 0 }}
                >
                  <IconCard
                    Icon={NewspaperOutlined}
                    card="cardSmall"
                    sxCard={{ minWidth: 54 }}
                  />
                  <Typography
                    variant="h5"
                    className={classes.title}
                    sx={{ fontWeight: 700, margin: 'auto 0' }}
                  >
                    {t('menu_campus_news.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={10}>
                {t('menu_campus_news.description')
                  .split('\n')
                  .map((item) => (
                    <Typography
                      variant="body1"
                      className={classes.description}
                      gutterBottom
                      key={item}
                    >
                      {item}
                    </Typography>
                  ))}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.leftContainer}>
              {!isLoaded ? (
                loadingRowSkeleton()
              ) : listNews.length !== 0 ? (
                <ImageList
                  sx={{
                    width: '90%',
                    height: '100%',
                    marginLeft: { xs: '1.5em', md: '0' },
                  }}
                  variant="quilted"
                  cols={numColums}
                  gap={30}
                >
                  {listNews?.slice(0, 5).map((item, index) => {
                    const { path } = item.assets[0];
                    const url = `${endpoint}${path}`;
                    const firstImageColsValue =
                      window.innerWidth < 700 ? 2 : Math.ceil(numColums / 2);
                    const ImageRowsValue =
                      numColums <= 2 && window.innerWidth > 700 ? 2 : 1;

                    if (index === 0) {
                      return (
                        <ImageListItem
                          key={item.id}
                          cols={firstImageColsValue}
                          rows={2}
                          onClick={() => defineModal(url, item.name)}
                        >
                          <img
                            {...srcSet(url, 121, 2, 2)}
                            alt={item.name}
                            className={classes.img}
                          />
                        </ImageListItem>
                      );
                    }
                    return (
                      <ImageListItem
                        key={item.id}
                        cols={1}
                        rows={ImageRowsValue}
                        onClick={() => defineModal(url, item.name)}
                      >
                        <img
                          {...srcSet(url, 121, 1, 1)}
                          alt={item.name}
                          className={classes.img}
                        />
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              ) : (
                <Typography
                  sx={{
                    marginTop: { xs: '10%', md: '2%' },
                    marginLeft: { xs: '10%', md: '2%' },
                    fontWeight: 700,
                  }}
                >
                  {t('menu_campus_news.empty_news')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
