/* eslint-disable import/prefer-default-export */

export const sortArrayByName = (x, y) => {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
};

export const sortArrayByNameByLettersThenNumbers = (x, y) => {
  const xStartsByNumber = x.name.match(/^\d/);
  const yStartsByNumber = y.name.match(/^\d/);

  if (
    (xStartsByNumber && yStartsByNumber) ||
    (!xStartsByNumber && !yStartsByNumber)
  )
    return sortArrayByName(x, y);
  if (xStartsByNumber && !yStartsByNumber) return 1;
  if (!xStartsByNumber && yStartsByNumber) return -1;
  return 0;
};
