// Formater en CSS à cause de Mui qui empêche de fixer la position du bouton contribuer
export default {
  container: {
    zIndex: 1000,
    margin: '1rem',
    right: 5,
    bottom: 5,
    display: 'flex',
    position: 'fixed',
  },
};
