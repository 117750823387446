import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MobileNavBar from 'components/MobileNavBar/MobileNavBar';
import { KeyboardReturnRounded, ExpandMore } from '@mui/icons-material/';
import useStyles from './InterventionRequestMobile.style';
import CreateRequestMobile from './CreateRequestMobile/CreateRequestMobile';
import MyRequests from './MyRequests/MyRequests';

export default function InterventionRequestMobile({
  categories,
  siteName,
  buildings,
  form,
  setFormValueOf,
  sendForm,
  errors,
  helperTextError,
  messageSent,
  setForm,
  anomalies,
  loadingAnomalies,
  deleteAnomaly,
  sendAnomalies,
  setSendAnomalies,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [toggleAccordionCreate, setToggleAccordionCreate] = useState(false);
  const [toggleAccordionMyRequest, setToggleAccordionMyRequest] =
    useState(false);

  useEffect(() => {
    if (sendAnomalies === 1) {
      setToggleAccordionCreate(false);
      setToggleAccordionMyRequest(true);
    }
  }, [sendAnomalies]);

  return (
    <div className={classes.main} data-testid="irMobile">
      <Typography
        variant="h5"
        sx={{ fontWeight: '700', letterSpacing: '0px', marginBottom: '1em' }}
        className={classes.title}
      >
        {t('header.bouton_contribuer')}
      </Typography>
      <Accordion
        expanded={toggleAccordionCreate}
        onChange={() => {
          setSendAnomalies(0);
          setToggleAccordionCreate(!toggleAccordionCreate);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore className={classes.icon} />}>
          <Typography
            className={classes.accordionTitles}
            sx={{ fontWeight: '700' }}
          >
            {t('intervention_request.intervention_request_tab_title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreateRequestMobile
            categories={categories}
            siteName={siteName}
            buildings={buildings}
            form={form}
            setFormValueOf={setFormValueOf}
            errors={errors}
            helperTextError={helperTextError}
            messageSent={messageSent}
            sendForm={sendForm}
            setForm={setForm}
            setToggleAccordionCreate={setToggleAccordionCreate}
            setToggleAccordionMyRequest={setToggleAccordionMyRequest}
            sendAnomalies={sendAnomalies}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ marginBottom: 100 }}
        expanded={toggleAccordionMyRequest}
        onChange={() => setToggleAccordionMyRequest(!toggleAccordionMyRequest)}
      >
        <AccordionSummary expandIcon={<ExpandMore className={classes.icon} />}>
          <Typography
            className={classes.accordionTitles}
            sx={{ fontWeight: '700' }}
          >
            {t('intervention_request.my_requests_tab_title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MyRequests
            loadingAnomalies={loadingAnomalies}
            siteName={siteName}
            buildings={buildings}
            anomalies={anomalies}
            deleteAnomaly={deleteAnomaly}
          />
        </AccordionDetails>
      </Accordion>
      {!toggleAccordionCreate && (
        <MobileNavBar
          onClickFirst={() => navigate(-1)}
          cardFirst="cardSmall"
          IconFirst={KeyboardReturnRounded}
        />
      )}
    </div>
  );
}

InterventionRequestMobile.propTypes = {
  siteName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf().isRequired,
  buildings: PropTypes.arrayOf().isRequired,
  form: PropTypes.shape({
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }).isRequired,
  setFormValueOf: PropTypes.func.isRequired,
  sendForm: PropTypes.func.isRequired,
  errors: PropTypes.bool.isRequired,
  helperTextError: PropTypes.func.isRequired,
  messageSent: PropTypes.node.isRequired,
  setForm: PropTypes.func.isRequired,
  anomalies: PropTypes.arrayOf().isRequired,
  loadingAnomalies: PropTypes.bool.isRequired,
  deleteAnomaly: PropTypes.func.isRequired,
  sendAnomalies: PropTypes.number.isRequired,
  setSendAnomalies: PropTypes.func.isRequired,
};
