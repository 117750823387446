import React from 'react';
import PropTypes from 'prop-types';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import './DesktopCalendar.css';
import moment from 'moment';
import 'moment/locale/fr';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UseLocationMenu from '../../../components/UseLocationMenu/UseLocationMenu';
import useStyles from './DesktopCalendar.style';
import CourseCard from '../CourseCard/CourseCard';
import CustomToolbar from './CustomToolbar/CustomToolbar';
import CustomDateHeader from './CustomDateHeader/CustomDateHeader';
import CustomDayHeader from './CustomDayHeader/CustomDayHeader';

const localizer = momentLocalizer(moment);

export default function DesktopCalendar({ dateIsBeforeSession, items }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const eventStyleGetter = () => {
    const style = {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      border: 'none',
      padding: 0,
      margin: 0,
      borderRadius: '5px',
    };

    return { style };
  };

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <UseLocationMenu />
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.gridCalendar}>
              <Calendar
                events={items}
                localizer={localizer}
                defaultView="work_week"
                views={['day', 'work_week']}
                defaultDate={dateIsBeforeSession()}
                min={new Date(0, 0, 0, 8, 0, 0)}
                max={new Date(0, 0, 0, 18, 0, 0)}
                titleAccessor={(event) => {
                  return event.name;
                }}
                startAccessor={(event) => {
                  return new Date(event.startDate);
                }}
                endAccessor={(event) => {
                  return new Date(event.endDate);
                }}
                messages={{
                  next: t('agenda.calendar_messages.next'),
                  previous: t('agenda.calendar_messages.previous'),
                  today: t('agenda.calendar_messages.today'),
                  month: t('agenda.calendar_messages.month'),
                  week: t('agenda.calendar_messages.week'),
                  day: t('agenda.calendar_messages.day'),
                  time: t('agenda.calendar_messages.time'),
                  event: t('agenda.calendar_messages.event'),
                  work_week: t('agenda.calendar_messages.work_week'),
                  yesterday: t('agenda.calendar_messages.yesterday'),
                  tomorrow: t('agenda.calendar_messages.tomorrow'),
                  agenda: t('agenda.calendar_messages.agenda'),
                }}
                components={{
                  event: CourseCard,
                  toolbar: CustomToolbar,
                  header: CustomDateHeader,
                  day: {
                    header: CustomDayHeader,
                  },
                }}
                eventPropGetter={eventStyleGetter}
                formats={{
                  eventTimeRangeFormat: () => null,
                  agendaHeaderFormat: () => null,
                }}
                timeslots={4}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}

DesktopCalendar.propTypes = {
  dateIsBeforeSession: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
