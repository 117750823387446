import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Pagination,
  Skeleton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridOverlay,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import moment from 'moment';
import { Select } from 'components/Inputs';
import { TrashCanOutline } from 'assets';
import { Box } from '@mui/system';
import styled from '@emotion/styled';
import Popup from 'components/Popup/Popup';

function MyAdministratorVision({
  loadingAnomalies,
  buildings,
  anomalies,
  deleteAnomaly,
  statusList,
  updateAnomaly,
  loadUser,
}) {
  const { t } = useTranslation();

  const [selectDelete, setSelectDelete] = useState([]);
  const [selectStatusUpdate, setSelectStatusUpdate] = useState([0, 0]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupOpenUpdate, setPopupOpenUpdate] = useState(false);
  const [resultNumber, setResultNumber] = useState(0);
  const [users, setUsers] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const cellClick = useRef(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);

  const configSorting = {
    sorting: {
      sortModel: [
        { field: 'createdAt', sort: 'desc' },
        { field: 'updatedAt', sort: 'desc' },
      ],
    },
  };

  useEffect(() => {
    if (anomalies.length > 0) {
      loadUser(anomalies?.map((ano) => ano.creatorUserId)).then((usersData) => {
        setUsers(usersData);
        setUserLoading(false);
      });
      setResultNumber(anomalies?.length || 0);
      setRows([...anomalies]);
    }
  }, [anomalies]);

  const FormatData = (anomaly) => {
    const {
      id,
      type,
      status,
      createdAt,
      updatedAt,
      creatorUserId,
      comment,
      floorRessourceId,
    } = anomaly;
    const building = buildings.find((b) =>
      b.floors.map((f) => parseInt(f.id, 10)).includes(floorRessourceId)
    );

    const floor = building?.floors?.find(
      (f) => parseInt(f.id, 10) === floorRessourceId
    );

    const user = users.find((u) => u.id === creatorUserId);
    return {
      id,
      declaring: user ? `${user.lastname} ${user.firstname}` : '',
      status,
      updatedAt: moment(updatedAt).format('DD/MM/YYYY') ?? '',
      createdAt: moment(createdAt).format('DD/MM/YYYY') ?? '',
      comment,
      site: `${building?.name ?? ''} ${floor?.name ?? ''} `,
      type: type.name,
      category: type.category.name,
    };
  };

  const columns = [
    {
      field: 'id',
      headerName: t('intervention_request.vision.identifier'),
      minWidth: 90,
      flex: 1,
    },
    {
      field: 'declaring',
      headerName: t('intervention_request.vision.declaring'),
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        !userLoading ? (
          <Tooltip
            title={
              params.value.length > 0
                ? params.value
                : t('intervention_request.vision.no_user')
            }
            style={{
              color: 'black',
            }}
            arrow
          >
            <p>
              {params.value.length > 0
                ? params.value
                : t('intervention_request.vision.no_user')}
            </p>
          </Tooltip>
        ) : (
          <Skeleton
            variant="text"
            animation="wave"
            width="100%"
            key={`declaring_skeleton_${params.id}`}
          />
        ),
    },
    {
      field: 'category',
      headerName: t('intervention_request.vision.category'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('intervention_request.vision.type_of_malfunction'),
      minWidth: 100,
      flex: 100,
    },
    {
      field: 'status',
      headerName: t('intervention_request.vision.status'),
      minWidth: 135,
      flex: 1,
      renderCell: (params) => (
        <Select
          required
          value={params.value.id || selectStatusUpdate}
          defaultValue={params.value.id}
          inputProps={{ 'data-testid': 'changeStatus' }}
          onChange={(e) => {
            const newRows = rows.map((row) => {
              if (row.id === params?.row.id) {
                const newStatus = statusList.find(
                  (status) => status.id === e.target.value
                );
                return {
                  ...row,
                  status: newStatus,
                };
              }
              return row;
            });
            setRows(newRows);

            setSelectStatusUpdate([params?.id, e.target.value]);
            setPopupOpenUpdate(true);
          }}
        >
          {statusList.map((option) => (
            <MenuItem key={option.name} value={option?.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'site',
      headerName: t('intervention_request.vision.site'),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: t('intervention_request.vision.create_date'),
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: t('intervention_request.vision.last_maj'),
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'comment',
      headerName: t('intervention_request.vision.comment'),
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <Tooltip
            title={value}
            style={{
              color: 'black',
            }}
            arrow
          >
            <p>{value}</p>
          </Tooltip>
        );
      },
    },
    {
      field: 'trash',
      headerName: '',
      minWidth: 80,
      flex: 1,
      renderHeader: (params) => {
        if (selectionModel.length > 1) {
          return (
            <TrashCanOutline
              data-testid="deleteIcon"
              cursor="pointer"
              onClick={() => {
                setSelectDelete(params.id);
                setPopupOpen(true);
              }}
            />
          );
        }

        return <></>;
      },
      renderCell: (params) => {
        return (
          <TrashCanOutline
            data-testid="deleteIcon"
            cursor="pointer"
            fill={
              params.row.status.name === t('my_request.array.create')
                ? 'black'
                : 'grey'
            }
            onClick={() => {
              if (params.row.status.name === t('my_request.array.create')) {
                setSelectDelete(params.id);
                setPopupOpen(true);
              }
            }}
            style={{
              marginLeft: '5px',
            }}
          />
        );
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Typography
          sx={{
            fontSize: '13px',
            opacity: '50%',
          }}
        >
          {t('my_request.array.results', { count: resultNumber })}
        </Typography>
      </GridToolbarContainer>
    );
  }
  // Pagination
  function CustomPagination() {
    const apiContext = useGridApiContext();
    const page = useGridSelector(apiContext, gridPageSelector);
    const pageCount = useGridSelector(apiContext, gridPageCountSelector);
    return (
      <Pagination
        color="secondary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiContext.current.setPage(value - 1)}
      />
    );
  }
  // Pas de résultat
  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column',
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>{t('my_request.array.no_results')}</Box>
      </StyledGridOverlay>
    );
  }

  // Pas de résultat après filtre
  function CustomNoResultsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>{t('my_request.array.no_results_filter')}</Box>
      </StyledGridOverlay>
    );
  }

  function LoadingRowOverlay() {
    const skeleton = [];
    for (let i = 0; i < 12; i += 1) {
      skeleton.push(
        <div
          style={{ position: 'absolute', top: 40 * i, width: '100%' }}
          key={`skeleton_container_${i}`}
        >
          <Skeleton
            variant="text"
            animation="wave"
            height={40}
            data-testid={`skeleton_card_${i}`}
            key={`skeleton_card_${i}`}
          />
        </div>
      );
    }
    return <GridOverlay>{skeleton}</GridOverlay>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12} style={{ minHeight: '650px' }}>
        {popupOpen && (
          <Popup
            title=""
            description={t(
              'intervention_request.vision.popup_delete_admin_vision.popup_description',
              {
                id: selectDelete,
              }
            )}
            buttonText={t(
              'intervention_request.vision.popup_delete_admin_vision.popup_yes'
            )}
            buttonTextClose={t(
              'intervention_request.vision.popup_delete_admin_vision.popup_no'
            )}
            handleClose={() => {
              setPopupOpen(false);
              setSelectDelete(0);
            }}
            handleAction={() => {
              deleteAnomaly(selectionModel);
              setPopupOpen(false);
              setSelectDelete(0);
            }}
          />
        )}
        {popupOpenUpdate && (
          <Popup
            title=""
            description={t('intervention_request.vision.popup_update', {
              id: selectStatusUpdate[0],
            })}
            buttonText={t('intervention_request.vision.validate_update')}
            buttonTextClose={t('intervention_request.vision.cancel_update')}
            handleClose={() => {
              setRows([...anomalies]);
              setPopupOpenUpdate(false);
              setSelectStatusUpdate([0, 0]);
            }}
            handleAction={() => {
              updateAnomaly(selectStatusUpdate[0], {
                statusId: selectStatusUpdate[1],
              });
              setPopupOpenUpdate(false);
              setSelectStatusUpdate([0, 0]);
            }}
          />
        )}

        <DataGrid
          initialState={configSorting}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoResultsOverlay,
            Pagination: CustomPagination,
            Toolbar: CustomToolbar,
            LoadingOverlay: LoadingRowOverlay,
          }}
          rows={rows?.map(FormatData)}
          columns={[...columns, { field: 'trash', sortable: false }]}
          disableColumnMenu
          disableVirtualization
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          hideFooterSelectedRowCount
          loading={loadingAnomalies}
          headerHeight={30}
          sx={{
            width: '100%',
            fontSize: '13px',
            backgroundColor: '#FFF',
            paddingX: '1em',
          }}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          filterModel={{
            items: [
              {
                columnField: 'status',
                operatorValue: 'contains',
              },
            ],
          }}
          columnBuffer={10}
          checkboxSelection
          isRowSelectable={(params) =>
            params.row.status.name === t('my_request.array.create')
          }
          check
          onCellClick={() => {
            cellClick.current = true;
          }}
          onColumnHeaderClick={() => {
            cellClick.current = true;
          }}
          selectionModel={selectionModel}
          // eslint-disable-next-line no-unused-vars
          onSelectionModelChange={(selection) => {
            if (cellClick.current) {
              if (selection.length > 0) {
                setSelectionModel(selection);
              } else {
                setSelectionModel([]);
              }
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

MyAdministratorVision.propTypes = {
  statusList: PropTypes.arrayOf().isRequired,
  updateAnomaly: PropTypes.func.isRequired,
  deleteAnomaly: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  loadingAnomalies: PropTypes.bool.isRequired,
  buildings: PropTypes.arrayOf().isRequired,
  anomalies: PropTypes.arrayOf().isRequired,
};

export default MyAdministratorVision;
