import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import useStyles from './UploadCard.style';
import './FileUploader.css';
import i18next from '../../translations/i18n';

function UploadCard({
  importLabel,
  uploadLabel,
  sizeMaxFile,
  typeFile,
  noSelectedFile,
  validate,
  fileSendLabel,
  uploadFile,
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [errorElement, setErrorElement] = useState('');
  const [send, setSend] = useState(false);
  const classes = useStyles();

  const handleChange = (fileUpload) => {
    if (fileUpload) {
      setErrorElement('');
    }
    setSend(false);
    setFile(fileUpload);
  };

  const handleTypeError = () => {
    setErrorElement(t('upload.error_type'));
    setFile(null);
  };

  const handleSizeError = () => {
    setErrorElement(t('upload.error_size'));
    setFile(null);
  };

  const handleSendUpload = () => {
    if (!file) {
      setErrorElement(t('upload.no_file'));
      return;
    }
    if (file && send) {
      setFile(null);
      setErrorElement(t('upload.no_file'));
      return;
    }
    uploadFile(file)
      .then((isUploaded) => {
        if (!isUploaded) {
          setErrorElement(t('upload.error_send_file'));
        }
        setSend(isUploaded);
      })
      .catch(() => {
        setSend(false);
        setErrorElement(t('upload.error_send_file'));
      });
  };

  const isSend = () => {
    if (send) {
      return (
        <>
          <CheckIcon
            sx={{
              margin: '0 0.2em',
              verticalAlign: 'middle',
              fontSize: '1.8rem',
            }}
          />
          {fileSendLabel}
        </>
      );
    }
    return (
      <>
        {t('upload.file')}: {file.name}
      </>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          width: '95%',
        }}
      >
        <Grid item xs={12}>
          <FileUploader
            classes="main"
            handleChange={handleChange}
            onSizeError={handleSizeError}
            onTypeError={handleTypeError}
            name="file"
            label={uploadLabel}
            maxSize="1"
            types={typeFile}
            hoverTitle={t('upload.drop')}
            // eslint-disable-next-line react/no-children-prop
            children={
              <Card className={classes.main}>
                <CardContent
                  className={classes.cardContent}
                  sx={{
                    padding: '4%',
                    '&:last-child': {
                      paddingBottom: '3%',
                    },
                    minHeight:
                      importLabel === t('news.import_label') ? '300px' : null,
                  }}
                >
                  <Grid container direction="row">
                    <Grid item xs={12} md={12} className={classes.grid}>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.title}
                        sx={{
                          padding: '0.6em 1.5em 0 1.5em',
                        }}
                      >
                        {importLabel}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', pb: '0.6em' }}>
                        {t('upload.type', {
                          type: typeFile.join(', '),
                        })}{' '}
                        {t('upload.size', {
                          size: sizeMaxFile,
                        })}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.grid}
                      sx={{
                        padding: '0.1em',
                      }}
                    >
                      <button type="button" className={classes.uploadButton}>
                        {uploadLabel}
                      </button>
                      <Typography
                        sx={{ mb: 1.5, mt: 1 }}
                        color={
                          !send || errorElement ? 'text.secondary' : '#0088CE'
                        }
                      >
                        {file ? isSend() : noSelectedFile}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={classes.grid}
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Typography
                        className={classes.error}
                        sx={{
                          margin: '0.5em',
                        }}
                      >
                        {errorElement}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignSelf: 'flex-end' }}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: {
                xs: '0.5em',
                sm: '1.5em',
                md: '2.5em',
                lg: '4em',
                xl: '7em',
              },
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSendUpload}
            >
              {validate}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

UploadCard.propTypes = {
  importLabel: PropTypes.string.isRequired,
  fileSendLabel: PropTypes.string.isRequired,
  sizeMaxFile: PropTypes.string.isRequired,
  typeFile: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploadLabel: PropTypes.string,
  noSelectedFile: PropTypes.string,
  validate: PropTypes.string,
};
UploadCard.defaultProps = {
  uploadLabel: i18next.t('upload.upload'),
  noSelectedFile: i18next.t('upload.no_selected_file'),
  validate: i18next.t('upload.validate'),
};

export default UploadCard;
