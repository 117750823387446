import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import CardAnomalyMobile from 'components/CardAnomalyMobile/CardAnomalyMobile';
import CardAnomalySkeletonMobile from 'components/CardAnomalySkeletonMobile/CardAnomalySkeletonMobile';
import Popup from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function MyRequests({
  loadingAnomalies,
  siteName,
  buildings,
  anomalies,
  deleteAnomaly,
}) {
  const { t } = useTranslation();
  const [PopupOpen, setPopupOpen] = useState(false);
  const [selectDelete, setSelectDelete] = useState(0);
  const FormatData = (anomaly) => {
    const {
      id,
      type,
      comment,
      updatedAt,
      createdAt,
      floorRessourceId,
      status,
    } = anomaly;
    const building = buildings.find((b) =>
      b.floors.map((f) => parseInt(f.id, 10)).includes(floorRessourceId)
    );
    const floor = building?.floors?.find(
      (f) => parseInt(f.id, 10) === floorRessourceId
    );

    return (
      <CardAnomalyMobile
        id={id}
        commentary={comment}
        status={status.name}
        category={type.category.name}
        type={type.name}
        place={`${siteName} ${building?.name ?? ''} ${floor?.name ?? ''} `}
        createdAt={moment(updatedAt).format('DD/MM/YYYY') ?? ''}
        updatedAt={moment(createdAt).format('DD/MM/YYYY') ?? ''}
        setSelectDelete={() => {
          setSelectDelete(id);
          setPopupOpen(true);
        }}
      />
    );
  };

  return (
    <Grid container direction="column">
      {PopupOpen && (
        <Popup
          title=""
          description={t('my_request.array.popup_description', {
            id: selectDelete,
          })}
          buttonText={t('my_request.array.popup_validate')}
          buttonTextClose={t('my_request.array.popup_cancel')}
          handleClose={() => {
            setPopupOpen(false);
            setSelectDelete(0);
          }}
          handleAction={async () => {
            deleteAnomaly(selectDelete);
            setPopupOpen(false);
            setSelectDelete(0);
          }}
        />
      )}
      {!loadingAnomalies ? (
        anomalies.map(FormatData)
      ) : (
        <CardAnomalySkeletonMobile />
      )}
    </Grid>
  );
}
MyRequests.propTypes = {
  siteName: PropTypes.string.isRequired,
  loadingAnomalies: PropTypes.bool.isRequired,
  buildings: PropTypes.arrayOf().isRequired,
  anomalies: PropTypes.arrayOf().isRequired,
  deleteAnomaly: PropTypes.func.isRequired,
};
