import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserModel from 'redux/models/user.model';
// eslint-disable-next-line import/no-cycle
import User from 'services/user.service';

const initialState = null;

export const me = createAsyncThunk('users/me', () => User.me());

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserState: (state, action) => {
      const userModel = new UserModel();

      userModel.mapFromUserClient({
        ...state,
        ...action.payload,
      });
      return userModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(me.fulfilled, (state, action) => {
        const userModel = new UserModel();

        userModel.mapFromUserClient({
          ...state,
          ...action.payload,
          loading: false,
        });
        return userModel;
      })
      .addCase(me.pending, (state, action) => {
        const userModel = new UserModel();

        userModel.mapFromUserClient({
          ...state,
          ...action.payload,
          loading: true,
        });
        return userModel;
      })
      .addCase(userSlice.actions.saveUserState, (state, action) => {
        const userModel = new UserModel();

        userModel.mapFromUserClient({
          ...state,
          ...action.payload,
          loading: true,
        });
        return userModel;
      });
  },
});

export const { saveUserState } = userSlice.actions;

export default userSlice.reducer;
