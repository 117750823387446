import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import UseLocationMenu from '../../components/UseLocationMenu/UseLocationMenu';
import UploadCard from '../../components/UploadCard/UploadCard';
import useStyles from './WeekMenuUpload.style';
import Ressource from '../../services/ressource.service';

export default function WeekMenuUpload() {
  const { t } = useTranslation();
  const classes = useStyles();
  const uploadFile = (file) => Ressource.uploadWeekMenu(file);

  return (
    <main>
      <Box className={classes.center}>
        <Grid container spacing={2} className={classes.background}>
          <UseLocationMenu />
          <Grid item xs={12}>
            <Grid container className={classes.leftContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  sx={{ fontWeight: 700, marginBottom: '0.3em' }}
                >
                  {t('week_menu_upload.title')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  gutterBottom
                >
                  {t('week_menu_upload.description')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.leftContainer}>
              <Grid item xs={12} sx={{ mb: '1em' }}>
                <UploadCard
                  importLabel={t('week_menu_upload.import_label')}
                  fileSendLabel={t('week_menu_upload.file_send')}
                  typeFile={['gif', 'jpeg', 'jpg', 'png']}
                  sizeMaxFile="1 Mo"
                  uploadFile={uploadFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  );
}
