import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Box,
  Button,
  Pagination,
  ButtonGroup,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import {
  DataGrid,
  GridOverlay,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Popup from 'components/Popup/Popup';
import useStyles from './MyRequestDesktop.style';
import { TrashCanOutline } from '../../../../assets';

function MyRequestDesktop({
  loadingAnomalies,
  siteName,
  buildings,
  anomalies,
  deleteAnomaly,
}) {
  const FormatData = (anomaly) => {
    const {
      id,
      type,
      comment,
      updatedAt,
      createdAt,
      floorRessourceId,
      status,
    } = anomaly;
    const building = buildings.find((b) =>
      b.floors.map((f) => parseInt(f.id, 10)).includes(floorRessourceId)
    );
    const floor = building?.floors?.find(
      (f) => parseInt(f.id, 10) === floorRessourceId
    );
    return {
      id,
      status: status.name,
      updatedAt: moment(updatedAt).format('DD/MM/YYYY') ?? '',
      createdAt: moment(createdAt).format('DD/MM/YYYY') ?? '',
      commentary: comment,
      place: `${siteName} ${building?.name ?? ''} ${floor?.name ?? ''} `,
      type: type.name,
      category: type.category.name,
    };
  };
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState('');
  const [PopupOpen, setPopupOpen] = useState(false);
  const [resultNumber, setResultNumber] = useState(anomalies.length || 0);
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [selectDelete, setSelectDelete] = useState(0);

  let numberRows;
  const tabsSx = {
    height: '3em',
    fontSize: '13px',
    color: 'black',
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: '0',
    borderBottom: 'solid rgba(0,0,0,0.5) 2px',
    width: 'auto',
    transform: 'translateY(2%)',
    '&:hover': { backgroundColor: 'transparent' },
  };

  const columns = [
    {
      field: 'category',
      headerName: t('my_request.array.category'),
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('my_request.array.type'),
      minWidth: 240,
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('my_request.array.status'),
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        let bgColor;
        let fColor;
        switch (params.value) {
          case `${t('my_request.array.create')}`:
            bgColor = '#0088CE';
            fColor = '#FFF';
            break;
          case `${t('my_request.array.in_progress')}`:
            bgColor = '#4D4F53';
            fColor = '#FFF';
            break;
          case `${t('my_request.array.waiting')}`:
            bgColor = '#F2F2F2';
            fColor = '#3C3C3B';
            break;
          default:
            bgColor = '#F3F3F3';
            fColor = '#3C3C3B';
            break;
        }
        return (
          <Typography
            sx={{
              backgroundColor: bgColor,
              color: fColor,
              fontSize: '13px',
              padding: '0.3em 1em',
              borderRadius: '50px',
            }}
            data-testid={params.value}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'place',
      headerName: t('my_request.array.place'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: t('my_request.array.create_date'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: t('my_request.array.update_date'),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'commentary',
      headerName: t('my_request.array.commentary'),
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <Tooltip
            title={value}
            style={{
              color: 'black',
            }}
            arrow
          >
            <p>{value}</p>
          </Tooltip>
        );
      },
    },
    {
      headerName: '',
      field: 'id',
      minWidth: 20,
      flex: 5,
      renderCell: (params) => (
        <TrashCanOutline
          data-testid="deleteIcon"
          onClick={() => {
            setSelectDelete(params.value);
            setPopupOpen(
              params.row.status === `${t('my_request.array.create')}`
            );
          }}
          cursor="pointer"
          fill={
            params.row.status === t('my_request.array.create')
              ? 'black'
              : 'grey'
          }
        />
      ),
    },
  ];

  // Header bar
  function CustomToolbar() {
    return (
      <>
        <GridToolbarContainer
          sx={{
            borderBottom: 'solid rgba(0,0,0,0.5) 1px',
            padding: '0.4em 0 0 0',
          }}
        >
          <ButtonGroup>
            <Button
              variant="contained"
              sx={
                selectedBtn === 0
                  ? {
                      ...tabsSx,
                      borderRadius: '0.4375rem 0 0 0',
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                      borderRadius: '0.4375rem 0 0 0',
                    }
              }
              className={classes.tabButton}
              data-testid="noFilterButton"
              onClick={() => {
                setFilterValue('');
                setResultNumber(anomalies.map(FormatData).length);
                setSelectedBtn(0);
              }}
            >
              {t('my_request.array.all')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 1
                  ? {
                      ...tabsSx,
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                    }
              }
              className={classes.tabButton}
              data-testid="createFilterButton"
              onClick={() => {
                setFilterValue(t('my_request.array.create'));
                numberRows = anomalies
                  .map(FormatData)
                  .filter(
                    (anomaly) => anomaly.status === t('my_request.array.create')
                  ).length;
                setResultNumber(numberRows);
                setSelectedBtn(1);
              }}
            >
              {t('my_request.array.create_title')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 2
                  ? {
                      ...tabsSx,
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                    }
              }
              className={classes.tabButton}
              data-testid="waitingFilterButton"
              onClick={() => {
                setFilterValue(t('my_request.array.waiting'));
                numberRows = anomalies
                  .map(FormatData)
                  .filter(
                    (anomaly) =>
                      anomaly.status === t('my_request.array.waiting')
                  ).length;
                setResultNumber(numberRows);
                setSelectedBtn(2);
              }}
            >
              {t('my_request.array.waiting')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 4
                  ? {
                      ...tabsSx,
                      borderRadius: '0 0.4375rem 0 0',
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                      borderRadius: '0 0.4375rem  0 0',
                    }
              }
              className={classes.tabButton}
              data-testid="inProgressFilterButton"
              onClick={() => {
                setFilterValue(t('my_request.array.in_progress'));
                numberRows = anomalies
                  .map(FormatData)
                  .filter(
                    (anomaly) =>
                      anomaly.status === t('my_request.array.in_progress')
                  ).length;
                setResultNumber(numberRows);
                setSelectedBtn(4);
              }}
            >
              {t('my_request.array.in_progress_title')}
            </Button>
            <Button
              variant="contained"
              sx={
                selectedBtn === 3
                  ? {
                      ...tabsSx,
                      fontWeight: 900,
                      opacity: '100%',
                      borderBottom: 'solid black 4px',
                    }
                  : {
                      ...tabsSx,
                    }
              }
              className={classes.tabButton}
              data-testid="closingFilterButton"
              onClick={() => {
                setFilterValue(t('my_request.array.closing'));
                numberRows = anomalies
                  .map(FormatData)
                  .filter(
                    (anomaly) =>
                      anomaly.status === t('my_request.array.closing')
                  ).length;
                setResultNumber(numberRows);
                setSelectedBtn(3);
              }}
            >
              {t('my_request.array.closing')}
            </Button>
          </ButtonGroup>
        </GridToolbarContainer>
        <GridToolbarContainer>
          <Typography
            sx={{
              fontSize: '13px',
              opacity: '50%',
            }}
          >
            {t('my_request.array.results', { count: resultNumber })}
          </Typography>
        </GridToolbarContainer>
      </>
    );
  }
  useEffect(() => setResultNumber(anomalies.length), [anomalies]);

  // Pagination
  function CustomPagination() {
    const apiContext = useGridApiContext();
    const page = useGridSelector(apiContext, gridPageSelector);
    const pageCount = useGridSelector(apiContext, gridPageCountSelector);
    return (
      <Pagination
        color="secondary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiContext.current.setPage(value - 1)}
      />
    );
  }

  // Pas de résultat
  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column',
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>{t('my_request.array.no_results')}</Box>
      </StyledGridOverlay>
    );
  }

  // Pas de résultat après filtre
  function CustomNoResultsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>{t('my_request.array.no_results_filter')}</Box>
      </StyledGridOverlay>
    );
  }

  function LoadingRowOverlay() {
    const skeleton = [];
    for (let i = 0; i < 12; i += 1) {
      skeleton.push(
        <div
          style={{ position: 'absolute', top: 40 * i, width: '100%' }}
          key={`skeleton_container_${i}`}
        >
          <Skeleton
            variant="text"
            animation="wave"
            height={40}
            key={`skeleton_card_${i}`}
          />
        </div>
      );
    }
    return <GridOverlay>{skeleton}</GridOverlay>;
  }

  return (
    <Grid container spacing={2} style={{ minHeight: '482px' }}>
      <Grid item md={12} xs={12}>
        {PopupOpen && (
          <Popup
            title=""
            description={t('my_request.array.popup_description', {
              id: selectDelete,
            })}
            buttonText={t('my_request.array.popup_validate')}
            buttonTextClose={t('my_request.array.popup_cancel')}
            handleClose={() => {
              setPopupOpen(false);
              setSelectDelete(0);
            }}
            handleAction={() => {
              deleteAnomaly(selectDelete);
              setPopupOpen(false);
              setSelectDelete(0);
            }}
          />
        )}
        <DataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoResultsOverlay,
            Pagination: CustomPagination,
            Toolbar: CustomToolbar,
            LoadingOverlay: LoadingRowOverlay,
          }}
          rows={anomalies.map(FormatData)}
          columns={columns}
          disableColumnMenu
          disableSelectionOnClick
          disableVirtualization
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          hideFooterSelectedRowCount
          loading={loadingAnomalies}
          headerHeight="2em"
          sx={{
            width: '100%',
            fontSize: '13px',
            backgroundColor: '#FFF',
            paddingX: '1em',
          }}
          pagination
          pageSize={5}
          rowsPerPageOptions={[5]}
          filterModel={{
            items: [
              {
                columnField: 'status',
                operatorValue: 'contains',
                value: filterValue,
              },
            ],
          }}
          columnBuffer={10}
          isRowSelectable={() => false}
        />
      </Grid>
    </Grid>
  );
}

MyRequestDesktop.propTypes = {
  siteName: PropTypes.string.isRequired,
  loadingAnomalies: PropTypes.bool.isRequired,
  buildings: PropTypes.arrayOf().isRequired,
  anomalies: PropTypes.arrayOf().isRequired,
  deleteAnomaly: PropTypes.func.isRequired,
};
export default MyRequestDesktop;
