import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './ImgLabelCard.style';

function ImgLabelCard({ label, link, img, sxCard }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = () => {
    navigate(link);
  };

  return (
    <Card className={classes.card} onClick={handleClick} sx={sxCard}>
      <CardContent className={classes.cardContent} sx={{ padding: 0 }}>
        <img alt={label} src={img} className={classes.icon} />
        <Typography variant="h6" className={classes.title} gutterBottom>
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}

ImgLabelCard.defaultProps = {
  sxCard: null,
};

ImgLabelCard.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  sxCard: PropTypes.shape(),
};

export default ImgLabelCard;
