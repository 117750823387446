import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    opacity: 1,
    marginBottom: '5em',
  },
  center: {
    flexGrow: 1,
    marginTop: '0.5em',
    padding: '1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(56px + 1em)`,
      padding: 0,
      marginTop: '1.5em',
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: 1,
  },
  description: {
    letterSpacing: '0px',
    color: '#000000',
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2em',
    },
    paddingRight: 0,
  },
  iframeOn: {
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
    borderRadius: '7px',
  },
  iframe: {
    [theme.breakpoints.down('md')]: {
      width: '80%',
      maxWidth: '90%',
    },
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '10px',
  },
  openImage: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
      height: 0,
    },
  },
}));

export default useStyles;
